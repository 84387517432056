import { useHistory } from "react-router-dom";
import { Button } from "../../Inputs";
import { ButtonProps } from "../../Inputs/Button/Button.types";
import { BakcIcon } from "../../Icons";
import { twMerge } from "tailwind-merge";

function BackButton({
  className,
  variant,
  ...props
}: ButtonProps) {
  const history = useHistory();

  return (
    <Button
      className={twMerge("mb-4", className)}
      startIcon={BakcIcon}
      variant="text"
      onClick={() => history.goBack()}
      {...props}
    />
  );
}

BackButton.defaultProps = {
  children: 'Back',
  color: 'primary'
} as ButtonProps;

export { BackButton }
