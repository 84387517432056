import { useGetProfile } from "src/app/modules/user_and_permissions/providers/User.provider";
import { SelectAsync } from "./SelectAsync.page";
import { safeArray } from "src/app/utils/array-utils";
import { getStoreCategory } from "src/app/modules/directory/services/StoreCategory.service";
import { useMemo } from "react";
import { StoreCategoryModel } from "src/app/modules/directory/models";
import { GroupBase, Props } from "react-select";
import { OmitSelectProps } from "./Select.types";
import { SelectAsyncProps } from "./SelectAsync.types";

export function SelectStoreCategory<
  IsMulti extends boolean = false,
  Group extends GroupBase<StoreCategoryModel> = GroupBase<StoreCategoryModel>
>(props: Omit<SelectAsyncProps<StoreCategoryModel>, 'selectOption' | 'fetchApi'> & Omit<Props<StoreCategoryModel, IsMulti, Group>, OmitSelectProps | 'options'>) {

  const profile = useGetProfile();

  const directoryIds = useMemo(
    () => safeArray(profile.data?.directories).map((_) => _.id),
    [profile.dataUpdatedAt]
  );

  return (
    <SelectAsync
      disabled={directoryIds.length < 1}
      fetchApi={getStoreCategory}
      selectOption={(_) => ({
        label: _.category_name!,
        value: _.id
      })}
      payload={{
        directory_ids: directoryIds,
        statuses: ["ACTIVE"],
        limit: 10,
        is_dropdown: true
      }}
      {...props}
    />
  );
}
