import { SVGProps } from "react";

export function CalendarIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      fill="none"
      viewBox="0 0 18 20"
      {...props}
    >
      <path
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1.292 10.646c0-5.796 1.932-7.728 7.728-7.728 5.796 0 7.729 1.932 7.729 7.728 0 5.797-1.932 7.729-7.73 7.729-5.795 0-7.728-1.932-7.728-7.729z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1.521 7.77h15.007M12.69 11.05h.008M9.024 11.05h.008M5.351 11.05h.008M12.69 14.26h.008M9.024 14.26h.008M5.351 14.26h.008M12.361 1.708v2.718M5.687 1.708v2.718"
      ></path>
    </svg>
  );
}
