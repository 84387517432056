import axios from "axios";
import { BaseResponse, Pagination, QueryModel, Response } from "src/app/models/api.types";
import {PaymentCategoryModel} from '../models/PaymentCategory.model'
import {useHistory} from 'react-router-dom'
export const URL_PAYMENT_CATEGORY = '/v1/cms/payment-categories';

export const getPaymentCategory = async (params?: QueryModel) => {
  return axios.get<BaseResponse<Pagination<PaymentCategoryModel[]>>>(URL_PAYMENT_CATEGORY, {
    params
  })
}

export const getPaymentCategoryDetail = (id: string) => {
  return axios.get<BaseResponse<Response<PaymentCategoryModel>>>(`${URL_PAYMENT_CATEGORY}/${id}`)
}

export const savePaymentCategory = (params: PaymentCategoryModel) => {
  if (!params.id) {
    return axios.post<BaseResponse<Response<PaymentCategoryModel>>>(URL_PAYMENT_CATEGORY, params)
  }

  return axios.put<BaseResponse<Response<PaymentCategoryModel>>>(`${URL_PAYMENT_CATEGORY}/${params.id}`, params)
}

export const deletePaymentCategory = (id: string) => {
  return axios.delete(`${URL_PAYMENT_CATEGORY}/${id}`)
}