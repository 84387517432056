
const PushNotificationScreens = {
  PUSH_NOTIFICATION: {
    PATH: '/push-notification',
    TITLE: 'Push Notification',
    TITLE_ALT: 'Push Notification',
    PERMISSION: 'push_notification.read',
  },
  LIST_PUSH_NOTIFICATION: {
    PATH: '/push-notification/index',
    TITLE: 'List Push Notification',
    TITLE_ALT: 'List',
    PERMISSION: 'push_notification.read',
  },
  ADD_PUSH_NOTIFICATION: {
    PATH: '/push-notification/add',
    TITLE: 'Add Push Notification',
    TITLE_ALT: 'Add Push Notification',
    PERMISSION: 'push_notification.create',
  },
  REUSE_PUSH_NOTIFICATION: {
    PATH: '/push-notification/reuse/:id',
    TITLE: 'Add Push Notification',
    TITLE_ALT: 'Add Push Notification',
    PERMISSION: 'push_notification.create',
  },
  DETAIL_PUSH_NOTIFICATION: {
    PATH: '/push-notification/detail/:id',
    TITLE: 'Push Notification',
    TITLE_ALT: 'Push Notification',
    PERMISSION: 'push_notification.read',
  },
  EDIT_PUSH_NOTIFICATION: {
    PATH: '/push-notification/edit/:id',
    TITLE: 'Push Notification',
    TITLE_ALT: 'Push Notification',
    PERMISSION: 'push_notification.update',
  }
};

export default PushNotificationScreens;
