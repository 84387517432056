import {
  twMerge
} from "tailwind-merge";
import {
  ChangeEvent,
  Ref,
  forwardRef,
  useLayoutEffect,
  useState
} from "react";

import {
  ToggleItemType,
  ToggleProps
} from "./Toggle.types";
import {
  Theme
} from "./Toggle.theme";

function _Toggle(
  {
    onChange = () => { },
    toggle = [],
    value,
    ...props
  }: ToggleProps,
  _: Ref<HTMLInputElement>
) {

  const [toggleData, setToggleData] = useState<ToggleItemType>({} as ToggleItemType);

  const hasToggle = toggle.length === 2;

  useLayoutEffect(() => {
    if (hasToggle) {
      if (value === undefined) {
        setToggleData(toggle[1]);
      } else {
        const _toggleData = toggle.find((_) => _.value === value);
        if (!!_toggleData) {
          setToggleData(_toggleData);
        }
      }
    }
  }, [value]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (hasToggle && !props.readOnly) {
      const _toggleData = (event.target.checked) ? toggle[0] : toggle[1];
      event.target.value = _toggleData?.value?.toString();
      setToggleData(_toggleData);
      onChange(event);
    }
  };

  return (
    <label className={twMerge(Theme.container)}>
      <input
        {...props}
        className="sr-only peer"
        onChange={handleInputChange}
        checked={hasToggle ? toggle[0]?.value === toggleData?.value : undefined}
        value={toggleData?.value?.toString()}
        type="checkbox"
      />
      <div className={twMerge(Theme.toggle)} />
      {(!!toggleData?.label) && <span className={twMerge(Theme.label)}>{toggleData?.label}</span>}
    </label>
  );
}

export const Toggle = forwardRef(_Toggle);
