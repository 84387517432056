import {
  useMutation,
  useQuery,
  useQueryClient
} from "@tanstack/react-query";
import {
  AxiosError,
  AxiosResponse
} from "axios";

import {
  URL_STORE,
  URL_STORE_IMAGE,
  deleteStore,
  getStore,
  getStoreDetail,
  saveStore,
  saveStoreImage
} from "../services/Store.service";
import {
  useApiParams,
} from "src/app/hooks";
import {
  BaseResponse,
  Pagination,
  ResponseList,
  Response,
  ResponseFieldError
} from "src/app/models/api.types";
import {
  createSelector
} from "@reduxjs/toolkit";
import {
  StoreImageModel,
  StoreModel,
  StoreParams
} from "../models/Store.model";
import {
  useParams
} from "react-router-dom";

const selectStore = createSelector(
  (state: AxiosResponse<BaseResponse<Pagination<StoreModel[]>>>) => state.data,
  (data: BaseResponse<Pagination<StoreModel[]>>) => data.response_output?.list
);
export function useGetStore() {

  const defaultValues = {
    merchant_category_ids: '',
    directory_ids: '',
    is_partner: ''
  };
  const [params] = useApiParams({ defaultValues });

  return useQuery<
    AxiosResponse<BaseResponse<Pagination<StoreModel[]>>>,
    AxiosError<BaseResponse<Response<any>>>,
    ResponseList<StoreModel[]> | undefined
  >({
    keepPreviousData: true,
    queryKey: [URL_STORE, params],
    queryFn: (_) => getStore(params, _.signal),
    select: selectStore,
  });
}

export function useGetMasterStore(params: StoreParams) {

  return useQuery<
    AxiosResponse<BaseResponse<Pagination<StoreModel[]>>>,
    AxiosError<BaseResponse<Response<any>>>,
    ResponseList<StoreModel[]> | undefined
  >({
    keepPreviousData: true,
    queryKey: [URL_STORE, params],
    queryFn: (_) => getStore(params, _.signal),
    enabled: !!params.directory_ids,
    select: selectStore,
  });
}

const selectStoreDetail = createSelector(
  (state: AxiosResponse<BaseResponse<Response<StoreModel>>>) => state.data,
  (data: BaseResponse<Response<StoreModel>>) => {
    const {
      created_by,
      updated_by,
      created_at,
      updated_at,
      deleted_at,
      floor,
      merchant_image,
      whatsapp_number,
      phone_number,
      ...rest
    } = data.response_output?.detail || {};
    return {
      ...rest,
      floor: floor?.map((_) => ({ label: _, value: _ })),
      merchant_image : merchant_image?.map(_ => ({
        image_id: _.id,
        merchant_id: _.merchant_id,
        image: _.image
      })),
      whatsapp_number: whatsapp_number?.replace("+",""),
      phone_number: phone_number?.replace("+",""),
    };
  }
);
export function useGetStoreDetail(id?:string) {

  const params = useParams<{ id: string }>();
  const selectedId = id || params.id;

  return useQuery<
    AxiosResponse<BaseResponse<Response<StoreModel>>>,
    AxiosError<BaseResponse<Response<any>>>,
    StoreModel | undefined
  >({
    keepPreviousData: false,
    queryKey: [`${URL_STORE}/${selectedId}`],
    queryFn: (_) => getStoreDetail(selectedId, _.signal),
    select: selectStoreDetail,
    enabled: !!selectedId
  });
}

export function useSaveStore() {

  return useMutation<
    AxiosResponse<BaseResponse<Response<StoreModel>>>,
    AxiosError<BaseResponse<ResponseFieldError>>,
    StoreModel
  >({
    mutationKey: [URL_STORE],
    mutationFn: saveStore
  });
}

export function useDeleteStore() {

  const refetchStore = useRefetchStore();

  return useMutation<
    AxiosResponse<BaseResponse<Response<{}>>>,
    AxiosError<BaseResponse<Response<any>>>,
    any
  >({
    mutationKey: [URL_STORE],
    mutationFn: deleteStore,
    onSuccess: refetchStore
  });
}

export function useRefetchStore() {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries({ queryKey: [URL_STORE] });
  };
}


export function useSaveStoreImage() {
  const refetchStore = useRefetchStore();

  return useMutation<
    AxiosResponse<BaseResponse<Response<StoreImageModel>>>,
    AxiosError<BaseResponse<ResponseFieldError>>,
    StoreImageModel
  >({
    mutationKey: [URL_STORE_IMAGE],
    mutationFn: saveStoreImage,
    onSuccess: refetchStore
  });
}
