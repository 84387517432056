import {twMerge} from 'tailwind-merge'
import {createElement, isValidElement} from 'react'

import {ControlLabelProps} from './ControlLabel.types'
import {Label} from '../Label/Label.page'
import {HelperText} from '../HelperText/HelperText.page'

export function ControlLabel({
  required,
  children,
  label,
  form,
  description,
  labelClassName,
  containerLabelClassName,
  ...props
}: ControlLabelProps) {
  return (
    <div className='grid items-start grid-cols-3' {...props}>
      {isValidElement(children) ? (
        (() => {
          const {helperText, className, name, rule, ...rest} = children.props
          if (!name || !form) {
            return (
              <>
                <div className={containerLabelClassName}>
                  <Label className={labelClassName} required={rule?.required || required}>{label}</Label>
                  {description && <HelperText>{description}</HelperText>}
                </div>
                {createElement(children.type, {
                  ...children.props,
                  className: twMerge(className, 'col-span-2'),
                  key: children.key,
                })}
              </>
            )
          }
          const error = form.formState.errors[name]
          return (
            <>
              <div className={containerLabelClassName}>
                <Label className={labelClassName} required={!!rule?.required}>{label}</Label>
                {description && <HelperText>{description}</HelperText>}
              </div>
              {createElement(children.type, {
                ...rest,
                ...form.register(name, rule),
                helperText: error?.message || helperText,
                className: twMerge(className, 'col-span-2'),
                required: !!rule?.required,
                error: !!error,
                key: children.key,
              })}
            </>
          )
        })()
      ) : (
        <>
          <div className={containerLabelClassName}>
            <Label className={labelClassName} {...{required}}>{label}</Label>
          </div>
          {children}
        </>
      )}
    </div>
  )
}
