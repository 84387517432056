import SwipeableViews from "react-swipeable-views";

import { useParams } from "react-router-dom";
import { createElement } from "react";

import { UnderlineTabs } from "../UnderlineTabs/UnderlineTabs.page";
import { usePageTabs } from "./PageTabs.hooks";
import { PageTabsData, PageTabsProps } from "./PageTabs.types";
import { PageTitle } from "../../Layout/header/HeaderTitle";

function PageTabs(props: PageTabsProps) {

  const params = useParams<{ path: string }>();
  const pageTabs = usePageTabs({
    baseRoute: props.baseRoute,
    data: props.data
  });

  const renderItem = (item: PageTabsData, index: number) => {
    if (!props.unmountOnHidden || index === pageTabs.tabIndex) {
      return createElement(item.component, { ...item, title: item.label, key: item.path });
    }
    return <div key={item.path} />;
  };

  return (
    <>
      <PageTitle description={pageTabs?.activeTab?.label}>
        {props.title}
      </PageTitle>
      <UnderlineTabs
        className="mb-5"
        onChange={pageTabs.setPage}
        index={pageTabs.tabIndex}
        items={pageTabs.tabs}
      />
      {(!!params.path) && (
        <SwipeableViews disableLazyLoading={false} index={pageTabs.tabIndex}>
          {pageTabs.tabs.map(renderItem)}
        </SwipeableViews>
      )}
    </>
  );
}

PageTabs.defaultProps = {
  unmountOnHidden: true,
} as PageTabsProps;

export { PageTabs }
