import { useForm } from "react-hook-form";
import { useRequestUpateAuth } from "../../providers/SetPassword.provider";
import { getErrorMessage } from "src/app/utils/api-utils";

const defaultForm = {
  type: 'forgot-password',
  email: '',
}

export function useForgotPassword() {

  const form = useForm({ defaultValues: defaultForm });
  const requestChangePassword = useRequestUpateAuth<keyof typeof defaultForm>({
    onError: (error) => {
      form.setError('email', { type: 'custom', message: getErrorMessage(error)?.en });
    }
  });

  const handleSubmit = form.handleSubmit((formData) => {
    requestChangePassword.mutate(formData);
  });

  return ({
    requestChangePassword,
    handleSubmit,
    form
  });
}
