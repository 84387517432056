import { ScreenProps } from "src/app/models/screen-prop";

const ReportScreens: ScreenProps = {
  REPORT: {
    PATH: '/report',
    TITLE: 'Report',
    TITLE_ALT: 'Report',
  },
  LIST_REPORT: {
    PATH: '/report/:path',
    TITLE: 'Report',
    TITLE_ALT: 'Report',
  }
};

export default ReportScreens;
