import axios from "axios";
import { BaseResponse, Pagination, QueryModel, Response } from "src/app/models/api.types";
import { PaymentMethodModel } from "../models/PaymentMethod.model";
export const URL_PAYMENT_METHOD = '/v1/cms/payment-methods';

export const getPaymentMethod = async (params?: QueryModel) => {
  return axios.get<BaseResponse<Pagination<PaymentMethodModel[]>>>(URL_PAYMENT_METHOD, {
    params
  })
}

export const getPaymentMethodDetail = (id: string) => {
  return axios.get<BaseResponse<Response<PaymentMethodModel>>>(`${URL_PAYMENT_METHOD}/${id}`)
}

export const savePaymentMethod = (params: PaymentMethodModel) => {
  if (!params.id) {
    return axios.post<BaseResponse<Response<PaymentMethodModel>>>(URL_PAYMENT_METHOD, params)
  }

  return axios.put<BaseResponse<Response<PaymentMethodModel>>>(`${URL_PAYMENT_METHOD}/${params.id}`, params)
}

export const deletePaymentMethod = (id: string) => {
  return axios.delete(`${URL_PAYMENT_METHOD}/${id}`)
}