import React, { FC, useEffect, useRef } from 'react'
import { useLocation } from 'react-router'
import { Tooltip } from 'react-tooltip'
import { MasterProvider } from 'src/app/context/MasterContext'
import { PageDataProvider } from 'src/app/context/PageDataContext'
import { HeaderToastProvider } from 'src/app/context/ToastContext'
import { HeaderToast } from '../Libs/Toast/GToast'
import ContentWrapper from './ContentWrapper'
import ScrollToTop from './ScrollToTop'
import AsideDefault from './aside/AsideDefault'
import { Footer } from './footer/Footer'
import HeaderWrapper from './header/HeaderWrapper'
import OverlayWrapper from './overlay/OverlayWrapper'

const MasterLayout: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const { pathname } = useLocation()

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0
      window.scrollTo(0, 0)
    }
  }, [pathname])

  return (
    <MasterProvider>
      <PageDataProvider>
        <HeaderToastProvider>
          <OverlayWrapper />

          <div className='pt-[80px] flex flex-row flex-[1_0_auto] bg-neutral-10 dark:bg-inverse-primary transition-all duration-300'>
            {/* <Aside /> */}
            <AsideDefault />

            <ContentWrapper>
              <HeaderWrapper />
              <HeaderToast />

              <div ref={scrollRef} className='py-6 flex flex-col flex-[1_0_auto]'>
                <div className='flex flex-[1_0_auto] px-8'>
                  <div id="content-wrapper" className='w-full mx-auto'>{children}</div>
                </div>
              </div>

              <Footer />
            </ContentWrapper>
          </div>
        </HeaderToastProvider>
      </PageDataProvider>

      <ScrollToTop />

      <Tooltip id='view-tooltip' className='tooltip-custom'>
        View
      </Tooltip>
      <Tooltip id='edit-tooltip' className='tooltip-custom'>
        Edit
      </Tooltip>
      <Tooltip id='delete-tooltip' className='tooltip-custom'>
        Delete
      </Tooltip>
      <Tooltip id='send-tooltip' className='tooltip-custom'>
        Send
      </Tooltip>
    </MasterProvider>
  )
}

export default MasterLayout
