import { twMerge } from "tailwind-merge";

import { StackProps } from "./Stack.types";

function Stack({
  className,
  direction,
  justify,
  items,
  style,
  gap,
  ...props
}: StackProps) {

  return (
    <div
      {...props}
      style={{ ...style, gap: gap * 6 }}
      className={twMerge(
        'flex w-full',
        `flex-${direction}`,
        `justify-${justify}`,
        !!items && `items-${items}`,
        className
      )}
    />
  );
}

Stack.defaultProps = {
  direction: 'col',
  justify: 'normal',
  gap: 4
} as StackProps;

export { Stack }
