import { FC } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { HeaderToast } from 'src/app/components/Libs/Toast/GToast'
import { HeaderToastProvider } from 'src/app/context/ToastContext'
import AuthScreens from './AuthScreens'

const AuthRoutes: FC = () => {
  return (
    <HeaderToastProvider>
      <HeaderToast variant='FULL' />

      <Switch>
        <Route
          component={AuthScreens.LOGIN_EMAIL.COMPONENT}
          path={AuthScreens.LOGIN_EMAIL.PATH}
        />
        <Route
          component={AuthScreens.FORGOT_PASSWORD.COMPONENT}
          path={AuthScreens.FORGOT_PASSWORD.PATH}
        />
        <Route
          component={AuthScreens.CREATE_NEW_PASSWORD.COMPONENT}
          path={AuthScreens.CREATE_NEW_PASSWORD.PATH}
        />

        <Redirect from='/auth' exact={true} to='/auth/login-email' />
        <Redirect to='/auth/login-email' />
      </Switch>
    </HeaderToastProvider>
  )
}

export default AuthRoutes
