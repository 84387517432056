import { SVGProps } from "react";

export function AddIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="16"
      fill="none"
      viewBox="0 0 15 16"
      {...props}
    >
      <path
        fill="currentColor"
        stroke="currentColor"
        d="M7.923 9.423h.5V4.077a.576.576 0 111.154 0v5.346h5.346a.576.576 0 110 1.154H9.577v5.346a.576.576 0 11-1.154 0v-5.346H3.077a.576.576 0 110-1.154h4.846z"
      ></path>
    </svg>
  );
}
