export const Theme = {
  input: "cursor-pointer",
  fitToContent: {
    onlyMonthPicker: "!w-[120px]",
    onlyYearPicker: "!w-[90px]",
  },
};

export const DatePickerVariants: Record<string, any> = {
  primary: {
    container: [
      'bg-primary-50  ',
      'hover:bg-primary-100',
      'rounded-lg',
    ],
    input: [
      "bg-transparent",
      "text-primary-500",
      "border-primary-500",
      "cursor-pointer"
    ]
  }
};
