const CustomerScreens = {
    CUSTOMER: {
        PATH: '/customer',
        TITLE: 'Customer',
        TITLE_ALT: 'Customer',
        PERMISSION: 'customer.read',
    },
    LIST_CUSTOMER: {
        PATH: '/customer/list-customer',
        TITLE: 'List Customer',
        TITLE_ALT: 'List',
        PERMISSION: 'customer.read',
    },
    DETAIL_CUSTOMER_INDEX: {
        PATH: '/customer/detail/:customerId/:segment',
        TITLE: 'Detail Customer',
        TITLE_ALT: 'Detail',
        PERMISSION: 'customer.read',
    },
    DETAIL_CUSTOMER_DETAIL_CUSTOMER: {
        PATH: '/customer/detail/:customerId/customer-detail',
        TITLE: 'Detail Customer',
        TITLE_ALT: 'Detail',
        PERMISSION: 'customer.read',
    },
    DETAIL_CUSTOMER_POINTS: {
        PATH: '/customer/detail/:customerId/points',
        TITLE: 'Points',
        TITLE_ALT: 'Points',
        PERMISSION: 'customer.read',
    },
    DETAIL_CUSTOMER_REWARDS: {
        PATH: '/customer/detail/:customerId/customer-reward',
        TITLE: 'Rewards',
        TITLE_ALT: 'Rewards',
        PERMISSION: 'customer.read',
    },
    ADD_BUYABLE_VOUCHER: {
        PATH: '/customer/detail/:customerId/customer-reward/add-buyable-voucher/:step',
        TITLE: 'Add Reward',
        TITLE_ALT: 'Add Reward',
        PERMISSION: ''
    },
    DETAIL_CUSTOMER_TRANSACTION_HISTORY: {
        PATH: '/customer/detail/:customerId/customer-transaction-history',
        TITLE: 'Transaction History',
        TITLE_ALT: 'Transaction History',
        PERMISSION: 'customer.read',
    },
    DETAIL_DETAIL_CUSTOMER_TRANSACTION_HISTORY: {
        PATH: '/customer/detail/:customerId/customer-transaction-history/detail/:id',
        TITLE: 'Detail Transaction History',
        TITLE_ALT: 'Detail Transaction History',
        PERMISSION: 'customer.read',
    },
    DETAIL_CUSTOMER_USED_PROMO: {
        PATH: '/customer/detail/:customerId/used-promo',
        TITLE: 'Used Promo',
        TITLE_ALT: 'Used Promo',
        PERMISSION: 'customer.read',
    },
    EDIT_CUSTOMER: {
        PATH: '/customer/edit/:customerId',
        TITLE: 'Edit Customer',
        TITLE_ALT: 'Edit',
        PERMISSION: 'customer.update',
    },
}

export default CustomerScreens