import CreateNewPasswordPage from './page/CreateNewPassword/CreateNewPassword.page';
import ForgotPasswordPage from './page/ForgotPassword/ForgotPassword.page';
import LoginEmailPage from './page/LoginEmail/LoginEmail.page';

const AuthScreens = {
  LOGIN_EMAIL: {
    COMPONENT: LoginEmailPage,
    PATH: '/auth/login-email'
  },
  FORGOT_PASSWORD: {
    COMPONENT: ForgotPasswordPage,
    PATH: '/auth/forgot-password'
  },
  CREATE_NEW_PASSWORD: {
    COMPONENT: CreateNewPasswordPage,
    PATH: '/auth/create-new-password'
  }
}

export default AuthScreens;
