import { ScreenProps } from "src/app/models/screen-prop";

const ManageMobileAppScreens: ScreenProps = {
  MANAGE_MOBILE_APP: {
    PATH: '/manage-mobile-app',
    TITLE: 'Manage Mobile App',
    TITLE_ALT: 'Manage Mobile App',
  },

  BANNER: {
    PATH: '/manage-mobile-app/banner',
    TITLE: 'Banner',
    TITLE_ALT: 'Banner',
    PERMISSION: 'manage_banner.read',
  },
  LIST_BANNER: {
    PATH: '/manage-mobile-app/banner/list/:path',
    TITLE: 'List Banner',
    TITLE_ALT: 'List Banner',
    PERMISSION: 'manage_banner.read',
  },
  ADD_BANNER: {
    PATH: '/manage-mobile-app/banner/add',
    TITLE: 'Add Banner',
    TITLE_ALT: 'Add Banner',
    PERMISSION: 'manage_banner.create',
  },
  ADD_BANNER_TARGET_CONTENT: {
    PATH: '/manage-mobile-app/banner/add/select-content',
    TITLE: 'Add Banner',
    TITLE_ALT: 'Add Banner',
    PERMISSION: 'manage_banner.create',
  },
  DETAIL_BANNER: {
    PATH: '/manage-mobile-app/banner/detail/:id',
    TITLE: 'Detail',
    TITLE_ALT: 'Detail',
    PERMISSION: 'manage_banner.read',
  },
  EDIT_BANNER: {
    PATH: '/manage-mobile-app/banner/edit/:id',
    TITLE: 'Edit Banner',
    TITLE_ALT: 'Edit Banner',
    PERMISSION: 'manage_banner.update',
  },
  EDIT_BANNER_TARGET_CONTENT: {
    PATH: '/manage-mobile-app/banner/edit/:id/select-content',
    TITLE: 'Edit Banner',
    TITLE_ALT: 'Edit Banner',
    PERMISSION: 'manage_banner.update',
  },
  CHOOSE_FEATURED_BANNER: {
    PATH: '/manage-mobile-app/banner/list/:path/choose-content',
    TITLE: 'Choose Banner',
    TITLE_ALT: 'Choose Banner',
    PERMISSION: 'manage_featured_banner.update',
  },
  DETAIL_FEATURED_BANNER: {
    PATH: '/manage-mobile-app/banner/detail/featured-banner/:id',
    TITLE: 'Detail Featured Banner',
    TITLE_ALT: 'Detail Featured Banner',
    PERMISSION: 'manage_featured_banner.read',
  },

  HAPPENING_NOW: {
    PATH: '/manage-mobile-app/happening-now',
    TITLE: 'Happening Now',
    TITLE_ALT: 'Happening Now',
    PERMISSION: 'manage_happening_now.read',
  },
  LIST_HAPPENING_NOW: {
    PATH: '/manage-mobile-app/happening-now/list/:path',
    TITLE: 'List Happening Now',
    TITLE_ALT: 'List Happening Now',
    PERMISSION: 'manage_happening_now.read',
  },
  ADD_HAPPENING_NOW: {
    PATH: '/manage-mobile-app/happening-now/add',
    TITLE: 'Add Content',
    TITLE_ALT: 'Add Content',
    PERMISSION: 'manage_happening_now.create',
  },
  EDIT_HAPPENING_NOW: {
    PATH: '/manage-mobile-app/happening-now/edit/:id',
    TITLE: 'Edit Content',
    TITLE_ALT: 'Edit Content',
    PERMISSION: 'manage_happening_now.update',
  },
  DETAIL_HAPPENING_NOW: {
    PATH: '/manage-mobile-app/happening-now/detail/:id',
    TITLE: 'Detail',
    TITLE_ALT: 'Detail',
    PERMISSION: 'manage_happening_now.read',
  },
  CHOOSE_FEATURED_HAPPENING_NOW: {
    PATH: '/manage-mobile-app/happening-now/list/:path/choose-content',
    TITLE: 'Choose Happening Now',
    TITLE_ALT: 'Choose Happening Now',
    PERMISSION: 'manage_featured_happening_now.update',
  },
  DETAIL_FEATURED_HAPPENING_NOW: {
    PATH: '/manage-mobile-app/happening-now/detail/featured-happening-now/:id',
    TITLE: 'Choose Happening Now',
    TITLE_ALT: 'Choose Happening Now',
    PERMISSION: 'manage_featured_happening_now.read',
  },
  LIST_VERSION: {
    PATH: '/manage-mobile-app/version/list',
    TITLE: 'App Versioning',
    TITLE_ALT: 'List App Versioning',
    PERMISSION: 'manage_version.read',
  },
  ADD_VERSION: {
    PATH: '/manage-mobile-app/version/add',
    TITLE: 'Add',
    TITLE_ALT: 'Add Version',
    PERMISSION: 'manage_version.create',
  },
  DETAIL_VERSION: {
    PATH: '/manage-mobile-app/version/detail/:id',
    TITLE: 'Detail',
    TITLE_ALT: 'Detail Version',
    PERMISSION: 'manage_version.read',
  },
  EDIT_VERSION: {
    PATH: '/manage-mobile-app/version/edit/:id',
    TITLE: 'Edit',
    TITLE_ALT: 'Edit Version',
    PERMISSION: 'manage_version.update',
  },
  LIST_ART_FOR_EVERYBODY: {
    PATH: '/manage-mobile-app/art-for-everybody/list',
    TITLE: 'List Art For Everybody',
    TITLE_ALT: 'List Art For Everybody',
    PERMISSION: 'manage_art.read',
  },
  ART_FOR_EVERYBODY: {
    PATH: '/manage-mobile-app/art-for-everybody',
    TITLE: 'Art For Everybody',
    TITLE_ALT: 'Art For Everybody',
    PERMISSION: 'manage_art.read',
  },
  ADD_ART_FOR_EVERYBODY: {
    PATH: '/manage-mobile-app/art-for-everybody/add/:directory_id',
    TITLE: 'Add',
    TITLE_ALT: 'Add Art For Everybody',
    PERMISSION: 'manage_art.create',
  },
  EDIT_ART_FOR_EVERYBODY: {
    PATH: '/manage-mobile-app/art-for-everybody/edit/:directory_id/:id',
    TITLE: 'Edit',
    TITLE_ALT: 'Edit Art For Everybody',
    PERMISSION: 'manage_art.update',
  },
  DETAIL_ART_FOR_EVERYBODY: {
    PATH: '/manage-mobile-app/art-for-everybody/detail/:id',
    TITLE: 'Detail',
    TITLE_ALT: 'Detail Art For Everybody',
    PERMISSION: 'manage_art.read',
  },
}

export default ManageMobileAppScreens;
