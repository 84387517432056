import { useDeleteCampaign, useGetCampaignDetail, useSaveCampaign } from "../../providers/Campaign.provider";

export function useCampaignDetailHooks() {

  const deleteCampaign = useDeleteCampaign();
  const saveCampaign = useSaveCampaign();
  const campaign = useGetCampaignDetail();

  return {
    deleteCampaign,
    saveCampaign,
    campaign
  };
}
