import React from 'react'
import { RenderHTMLProps } from './RenderHTML.types'
import { twMerge } from 'tailwind-merge'
import { Theme } from './RenderHTML.theme'

export const RenderHTML = ({content = "", className}: RenderHTMLProps) => {
  return (
    <div 
        className={twMerge([Theme.wrapper, className])} 
        dangerouslySetInnerHTML={{ __html: content }} />
  )
}

