
export function hasText(str?: any): str is string {
  return !!str?.toString();
}

export function toCapitalize(str?: string | null) {
  if (!!str) {
    const strings = str.split(' ');
    const mapStrings = strings.map((item) => {
      const value = item.toLocaleLowerCase();
      const firstLetter = value.charAt(0).toUpperCase();
      return firstLetter + value.substring(1);
    });
    return mapStrings.join(' ');
  }
  return '';
}
