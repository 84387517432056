export const validatePassword = (value:any) => {
    let isValid = true
    const messages = [
      '<p>Your new password must have at least :</p>',
      '<ul class="list-disc pl-4">',
    ]

    const hasSpecificLength = value.length >= 8
    const containAtLeastOneNumber = /\d/.test(value)
    const hasLowercaseAndUppercaseCharacter = /[a-z]/.test(value) && /[A-Z]/.test(value)
    const hasSpecialCharacter = /[!@#$%^&*]/.test(value)

    if (!hasSpecificLength) {
      messages.push("<li>Has 8 characters</li>")
      isValid = false
    }

    if (!containAtLeastOneNumber) {
      messages.push("<li>Contain at least 1 number (0-9)</li>")
      isValid = false
    }

    if (!hasLowercaseAndUppercaseCharacter) {
      messages.push("<li>Has lowercase (a-z) and uppercase (A-Z) characters</li>")
      isValid = false
    }

    if (!hasSpecialCharacter) {
      messages.push("<li>Has special character (!@#$%^&*)</li>")
      isValid = false
    }

    if (!isValid) {
      messages.push('</ul>')
      return messages.join("")
    }

    return undefined
  }