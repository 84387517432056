import { Sort } from "src/app/models/api.types";
import { GetParamKey } from "./TableCell.types";

interface GetRollingSortParams<P extends Record<string, any>> {
  paramKey?: string;
  params: P;
  order?: string;
}

export function getRollingSort<P extends Record<string, any>>({
  paramKey,
  params,
  order
}: GetRollingSortParams<P>) {

  const param = getParamKey(paramKey);
  if (params?.order !== order) {
    return {
      [param.page]: !params?.page ? undefined : '1',
      [param.sort]: Sort.ASC,
      [param.order]: order
    };
  }
  const rollingSort = [
    {
      value: Sort.DESC,
      key: Sort.ASC
    },
    {
      value: undefined,
      key: Sort.DESC
    },
    {
      value: Sort.ASC,
      key: undefined
    },
  ].find((_) => _.key === params?.sort);

  if (!!rollingSort) {
    return {
      [param.order]: !rollingSort.value ? undefined : order,
      [param.page]: !params?.page ? undefined : '1',
      [param.sort]: rollingSort.value
    };
  }
}

export function getParamKey(key?: string) {
  return [
    'page',
    'limit',
    'sort',
    'order'
  ].reduce((stack, item) => {
    const value = !key ? item : `${key}.${item}`;
    return { ...stack, [item]: value };
  }, {} as GetParamKey);
}
