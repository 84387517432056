export const Theme = {
  input: [
    'focus:border focus:border-primary-200 focus:ring focus:ring-primary-100',
    'rounded-lg'
  ],
  inputError: [
    'border border-danger',
    'focus:ring-danger-200 focus:border-danger',
    'dark:focus:ring-danger dark:focus:border-danger'
  ],
};