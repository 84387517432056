import { SVGProps } from "react";

function EmailIcon(props: SVGProps<SVGSVGElement>) {
  return(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M16.421 5.245C15.218 4.16 13.191 3.75 10 3.75c-3.191 0-5.218.409-6.421 1.495m12.842 0c1.08.975 1.496 2.495 1.496 4.755 0 4.78-1.863 6.25-7.917 6.25S2.083 14.78 2.083 10c0-2.26.417-3.78 1.496-4.755m12.842 0l-5.242 5.243c-.651.651-1.707.651-2.357 0L3.579 5.245"
      ></path>
    </svg>
  );
}

export default EmailIcon;
