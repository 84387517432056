const SettingsScreens = {
    SETTINGS: {
      PATH: '/settings',
      TITLE: 'Settings',
      TITLE_ALT: 'Settings',
    },
    TERMS_AND_CONDITIONS: {
      PATH: '/settings/terms-and-conditions',
      TITLE: 'Terms And Conditions',
      TITLE_ALT: 'Terms And Conditions',
      PERMISSION: 'manage_terms_&_condition.read',
    },
    ABOUT_US: {
      PATH: '/settings/about-us',
      TITLE: 'About Us',
      TITLE_ALT: 'About Us',
      PERMISSION: 'manage_about_us.read',
    },
    PRIVACY_POLICY: {
      PATH: '/settings/privacy-policy',
      TITLE: 'Privacy Policy',
      TITLE_ALT: 'Privacy Policy',
      PERMISSION: 'manage_privacy_policy.read',
    },
    LIST_FAQ: {
      PATH: '/settings/faq/index',
      TITLE: 'FAQ',
      TITLE_ALT: 'List FAQ',
      PERMISSION: 'manage_faq.read',
    },
    ADD_FAQ: {
      PATH: '/settings/faq/add',
      TITLE: 'Add',
      TITLE_ALT: 'Add FAQ',
      PERMISSION: 'manage_faq.create',
    },
    DETAIL_FAQ: {
      PATH: '/settings/faq/detail/:id',
      TITLE: 'Detail',
      TITLE_ALT: 'Detail FAQ',
      PERMISSION: 'manage_faq.read',
    },
    EDIT_FAQ: {
      PATH: '/settings/faq/edit/:id',
      TITLE: 'Edit',
      TITLE_ALT: 'Edit FAQ',
      PERMISSION: 'manage_faq.update',
    },
    LIST_PAYMENT_METHOD: {
      PATH: '/settings/payment-method/index',
      TITLE: 'Payment Method',
      TITLE_ALT: 'List Payment Method',
      PERMISSION: 'manage_payment_methods.read',
    },
    ADD_PAYMENT_METHOD: {
      PATH: '/settings/payment-method/add',
      TITLE: 'Add',
      TITLE_ALT: 'Add Payment Method',
      PERMISSION: 'manage_payment_methods.create',
    },
    DETAIL_PAYMENT_METHOD: {
      PATH: '/settings/payment-method/detail/:id',
      TITLE: 'Detail',
      TITLE_ALT: 'Detail Payment Method',
      PERMISSION: 'manage_payment_methods.read',
    },
    EDIT_PAYMENT_METHOD: {
      PATH: '/settings/payment-method/edit/:id',
      TITLE: 'Edit',
      TITLE_ALT: 'Edit Payment Method',
      PERMISSION: 'manage_payment_methods.update',
    },
    LIST_PAYMENT_CATEGORY: {
      PATH: '/settings/payment-category/index',
      TITLE: 'Payment Category',
      TITLE_ALT: 'List Payment Category',
      PERMISSION: 'manage_payment_categories.read',
    },
    ADD_PAYMENT_CATEGORY: {
      PATH: '/settings/payment-category/add',
      TITLE: 'Add',
      TITLE_ALT: 'Add Payment Category',
      PERMISSION: 'manage_payment_categories.create',
    },
    EDIT_PAYMENT_CATEGORY: {
      PATH: '/settings/payment-category/edit/:id',
      TITLE: 'Edit',
      TITLE_ALT: 'Edit Payment Category',
      PERMISSION: 'manage_payment_categories.update',
    },
    DETAIL_PAYMENT_CATEGORY: {
      PATH: '/settings/payment-category/detail/:id',
      TITLE: 'Detail',
      TITLE_ALT: 'Detail Payment Category',
      PERMISSION: 'manage_payment_categories.read',
    },
    CONTACT_US: {
      PATH: '/settings/contact-us',
      TITLE: 'Contact Us',
      TITLE_ALT: 'Contact Us',
      PERMISSION: 'manage_contact_us.read',
    },
    ADD_CONTACT_US: {
      PATH: '/settings/contact-us/add',
      TITLE: 'Contact Us',
      TITLE_ALT: 'Contact Us',
      PERMISSION: 'manage_contact_us.read',
    },
    EDIT_CONTACT_US: {
      PATH: '/settings/contact-us/edit',
      TITLE: 'Contact Us',
      TITLE_ALT: 'Contact Us',
      PERMISSION: 'manage_contact_us.read',
    },
  DETAIL_CONTACT_US: {
      PATH: '/settings/contact-us/detail',
      TITLE: 'Contact Us',
      TITLE_ALT: 'Contact Us',
      PERMISSION: 'manage_contact_us.read',
    },
  }
  
  export default SettingsScreens;
  