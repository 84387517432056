import nl2br from "react-nl2br";

import { twMerge } from "tailwind-merge";
import { Ref, forwardRef } from "react";

import { DialogContent } from "../Dialog/DialogContent.page";
import { DialogTitle } from "../Dialog/DialogTitle.page";
import { Dialog } from "../Dialog/Dialog.page";
import { AlertProps, AlertContentProps } from "./Alert.types";
import { Button } from "../../Inputs";
import { DialogRef } from "../Dialog/Dialog.types";
import { DialogAction } from "../Dialog/DialogAction.page";
import { Typography } from "../../Display";
import { Theme } from "./Alert.theme";
import { useDialogContext } from "../Dialog/Dialog.context";

function AlertContent({
  cancelText,
  submitText,
  children,
  onSubmit,
  payload,
  title,
  ...props
}: AlertContentProps) {

  const dialogContext = useDialogContext();

  const handleSubmit = () => {
    if (!props.mutation || !payload) {
      onSubmit?.(dialogContext.data);
    } else {
      props.mutation.mutate(payload);
    }
  };

  return (
    <>
      <DialogTitle title={title} />
      <DialogContent>
        {typeof (children) === "string" ? <Typography>{nl2br(children)}</Typography> : children}
      </DialogContent>
      {(cancelText || submitText) && (
        <DialogAction {...props}>
          <Button className={twMerge(Theme.button, !cancelText && "hidden")}>
            {cancelText}
          </Button>
          <Button onClick={handleSubmit} className={twMerge(Theme.button, !submitText && "hidden")}>
            {submitText}
          </Button>
        </DialogAction>
      )}
    </>
  );
}

const Alert = forwardRef((
  {
    onSuccessGoBack,
    cancelText,
    submitText,
    mutation,
    children,
    onSubmit,
    payload,
    loading,
    onHide,
    title,
    ...props
  }: AlertProps,
  ref: Ref<DialogRef>
) => {

  return (
    <Dialog
      closeOnBackdropClicked={true}
      size="xs"
      ref={ref}
      {...props}
    >
      <AlertContent
        {...{
          onSuccessGoBack,
          cancelText,
          submitText,
          mutation,
          children,
          onSubmit,
          payload,
          loading,
          title
        }}
      />
    </Dialog>
  );
});

Alert.defaultProps = {
  cancelText: 'Cancel',
  submitText: 'OK'
} as AlertProps;

export { Alert }
