import { useForm } from "react-hook-form";
import { useLoginByEmail } from "../../providers/Auth.provider";

const defaultForm = {
  email: '',
  password: ''
};

export function useLoginEmail() {

  const loginEmail = useLoginByEmail<keyof typeof defaultForm>();
  const form = useForm({ defaultValues: defaultForm });

  const handleSubmit = form.handleSubmit((formData) => {
    loginEmail.mutate(formData);
  })

  return ({
    handleSubmit,
    loginEmail,
    form
  });
}
