import CampaignScreens from "../../CampaignScreens";

import { PageTitle } from "src/app/components/Layout/header/HeaderTitle";
import { CampaignForm } from "./CampaignForm/CampaignForm.page";

export default function CampaignEditPage() {

  const breadcrumbs = [
    {
      isActive: false,
      path: CampaignScreens.LIST_CAMPAIGN.PATH.replace(':path', 'index'),
      title: CampaignScreens.LIST_CAMPAIGN.TITLE,
    },
    { isActive: false, path: '', title: '', isSeparator: true },
  ];

  return (
    <>
      <PageTitle description="Edit" {...{ breadcrumbs }}>
        {CampaignScreens.CAMPAIGN.TITLE}
      </PageTitle>
      <CampaignForm title={CampaignScreens.EDIT_CAMPAIGN.TITLE} />
    </>
  );
}
