
export const Theme = {
  label: [
    'block mb-2 text-sm font-normal text-gray-900',
    'dark:text-white'
  ],
  labelError: [
    'text-danger'
  ]
};
