import React from 'react'
import { useGetProfile } from 'src/app/modules/user_and_permissions/providers/User.provider'
import { RegisterOptions, UseFormReturn } from 'react-hook-form'
import { InputSelect } from './InputSelect.page'

type InputSelectMallProps = {
    form: UseFormReturn<any, any, undefined>
    isMulti?: boolean
    rule?: RegisterOptions<any, string>
}

const InputSelectMall = (props: InputSelectMallProps) => {
    const profile = useGetProfile()

    return (
        <InputSelect
            {...props}
            placeholder="Select mall"
            options={profile.data?.directories?.map(item => ({
                label: item?.directory_name!,
                value: item?.id!,
                ...item
            }))}
            selectOption={(item)=> ({
                label: item.directory_name!,
                value: item.id!
            })}
            name={props.isMulti ? "directories" : "directory"}
            isMulti={props.isMulti}
            className='col-span-2'
        />
    )
}

export default InputSelectMall