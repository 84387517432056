export * from './BackButton/BackButton.page';
export * from './Link/Link.page';
export * from './UnderlineTabs/UnderlineTabs.page';
export * from './Paging/Paging.page';
export * from './InputSearch/InputSearch.page';
export * from './ScrollIntoContent/ScrollIntoContent.page';
export * from './PageTabs/PageTabs.page';

export * from './UnderlineTabs/UnderlineTabs.type';
export * from './PageTabs/PageTabs.types';
