import {
  useHistory
} from "react-router-dom";
import {
  useEffect,
  useRef
} from "react";

import {
  Button,
  Dialog,
  DialogAction,
  DialogContent,
  DialogRef,
  Stack,
  Typography
} from "src/app/components";

import { useCreateNewPassword } from "./CreateNewPassword.hooks";
import { HookFormProvider, InputTextField } from "src/app/components/HookForm";
import AuthScreens from "../../AuthScreens";
import { twMerge } from "tailwind-merge";
import { validatePassword } from "./CreateNewPassword.utils";

function CreateNewPasswordPage() {

  const alertPasswordUpdatedRef = useRef<DialogRef>(null);
  const history = useHistory();
  const state = useCreateNewPassword();

  useEffect(() => {
    if (state.updatePassword.isSuccess) {
      alertPasswordUpdatedRef.current?.show();
    }
  }, [state.updatePassword.isSuccess]);

  return (
    <div
      className="flex justify-center items-center h-[100vh] !bg-no-repeat !bg-[length:320px_auto]"
      style={{ background: "url(/assets/password/img/ellipse-tl.png) top left, url(/assets/password/img/ellipse-br.png) bottom right" }}
    >
      <Stack gap={8} className="max-w-md">
        <img
          className="w-20 mx-auto"
          src="/assets/login/img/pg-card.png"
          alt="PG Card"
        />
        {(state.verifyToken.isError || state.updatePassword.error) ? (
          <>
            <Stack gap={2} className="text-center">
              <Typography variant="h4" className="font-bold">Unable to Reset Password</Typography>
              <p className="text-neutral-500 text-[16px]">Sorry, you’re unable to reset your password <br />  because the link is already expired.</p>
            </Stack>
            <Button onClick={() => history.replace(AuthScreens.LOGIN_EMAIL.PATH)} size="lg">
              Back to Login
            </Button>
          </>
        ) : (
          <>
            <Stack gap={2} className="text-center">
              <Typography variant="h4" className="font-bold">Create New Password</Typography>
              <Typography className="text-neutral-500 text-[16px]">You’ll use this new password next time you’re logging in</Typography>
            </Stack>
            <HookFormProvider form={state.form}>
              <div>
                <InputTextField
                  placeholder="Enter new password"
                  required={true}
                  label="New Password"
                  rule={{
                    required: "Field cannot be empty",
                    validate: validatePassword
                  }}
                  name="password"
                  type="password"
                  helperText=""
                />

                <div
                  className={twMerge(["text-xs mt-2 text-danger-500 dark:text-danger-500", !state.form.formState.errors.password && "hidden"])}
                  dangerouslySetInnerHTML={{ __html: state.form.formState.errors.password?.message ?? "" }}
                />
              </div>

              <InputTextField
                placeholder="Enter confirm password"
                required={true}
                label="Confirm Password"
                name="confirm_password"
                type="password"
                rule={{
                  required: "Field cannot be empty",
                  validate: {
                    matchPattern: (_) => state.form.getValues("password") === _ || "Your new password and confirm password does not match"
                  }
                }}
              />
            </HookFormProvider>
            <Button
              loading={state.verifyToken.isLoading || state.updatePassword.isLoading}
              onClick={state.handleSubmit}
              size="lg"
            >
              Save
            </Button>
          </>
        )}
      </Stack>


      <Dialog
        closeOnBackdropClicked={true}
        title={"Password Updated!"}
        ref={alertPasswordUpdatedRef}
        size="xs"
      >
        <DialogContent>
          <Typography>
            Please login to your account using your new password.
          </Typography>
        </DialogContent>
        <DialogAction>
          <Button onClick={() => history.replace(AuthScreens.LOGIN_EMAIL.PATH)}>Back to Login</Button>
        </DialogAction>
      </Dialog>

    </div>
  );
}

export default CreateNewPasswordPage;
