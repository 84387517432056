import { SVGProps } from "react";

export function PasswordIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M13.333 8.692V5.833a3.333 3.333 0 00-6.666 0v2.859m6.666 0c-.843-.253-1.937-.359-3.333-.359-1.396 0-2.49.106-3.333.359m6.666 0c1.852.554 2.5 1.817 2.5 4.225 0 3.505-1.372 4.583-5.833 4.583-4.46 0-5.833-1.078-5.833-4.583 0-2.408.647-3.671 2.5-4.225"
      ></path>
    </svg>
  );
}
