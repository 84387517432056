import {twMerge} from 'tailwind-merge'

import {Stack} from '../../Layouts'
import {PagingProps} from './Paging.types'
import {Theme} from './Paging.theme'
import {ChevronLeftIcon, ChevronRightIcon} from '../../Icons'
import {useUrlSearchParams} from 'src/app/hooks'

type _PagingProps<T> = PagingProps<T> & {
  searchParams?: {
    [x: string]: string | undefined
  },
  setSearchParams?: (props: {[x: string]: string | undefined}) => void
  limitKey?: string
  pageKey?: string
}

export function Paging<T>({
  className,
  query,
  searchParams,
  setSearchParams,
  limitKey = 'limit',
  pageKey = 'page',
  ...props
}: _PagingProps<T>) {
  const [ defaultSearchParams, defaultSetSearchParams ] = useUrlSearchParams<'page' | 'limit'>()
  setSearchParams = setSearchParams || defaultSetSearchParams
  const limit = searchParams?.[limitKey] || defaultSearchParams.limit || '0'
  const page = searchParams?.[pageKey] || defaultSearchParams.page || '0'

  const total = query?.data?.pagination?.total || 0
  const totalPage = Math.ceil(+total / +limit)
  const isStartPage = +page < 5
  const isLessPage = totalPage <= 7
  const isEndPage = !isLessPage && +page > +totalPage - 4
  const loading = query?.isFetching || query?.isLoading

  const getLabelPage = (index: number) => {
    if (isStartPage || isLessPage) {
      return index + 2
    }
    if (isEndPage) {
      return +totalPage - 4 + index
    }
    return +page - 1 + index
  }

  const renderItem = (_: any, index: number) => {
    const currentPage = getLabelPage(index).toString()
    return !!page ? (
      <Button active={currentPage === page} page={currentPage} key={currentPage} {...{loading}} />
    ) : null
  }

  const getpageLength = () => {
    let pageLength = 3
    if (isLessPage) {
      pageLength = 5
    } else if (isStartPage || isEndPage) {
      pageLength = 4
    }
    return Math.min(+totalPage - 2, pageLength)
  }

  return (
    <Stack
      direction='row'
      justify='between'
      items='center'
      className={twMerge('ws:p-1 p-6 ws:!justify-start ', className)}
      {...props}
    >
      <select
        onChange={(_) => setSearchParams?.({[limitKey]: _.target.value, [pageKey]: '1'})}
        className={twMerge(Theme.select)}
        value={limit}
      >
        <option>10</option>
        <option>20</option>
        <option>50</option>
        <option>100</option>
      </select>

      <Stack direction='row' gap={2} className='w-auto ws:!gap-1'>
        <button
          className={twMerge(Theme.button, loading && Theme.buttonLoading)}
          disabled={loading || page === '1'}
          onClick={() => setSearchParams?.({page: (+page - 1).toString()})}
          key='prev'
        >
          <ChevronLeftIcon />
        </button>
        <Button active={page === '1'} page='1' key='first' {...{loading}} />
        <Etc visible={!isLessPage && !isStartPage} />
        {Array.from({length: getpageLength()}, renderItem)}
        <Etc visible={!isLessPage && !isEndPage} />
        {totalPage > 1 && (
          <Button
            active={+page === totalPage}
            page={totalPage.toString()}
            key='last'
            {...{loading}}
          />
        )}
        <button
          className={twMerge(Theme.button, loading && Theme.buttonLoading)}
          disabled={loading || +page === totalPage}
          onClick={() => setSearchParams?.({[pageKey]: (+page + 1).toString()})}
          key='next'
        >
          <ChevronRightIcon />
        </button>
      </Stack>
    </Stack>
  )
}

interface ButtonProps {
  disabled?: boolean
  loading?: boolean
  active?: boolean
  page: string
}
function Button(props: ButtonProps) {
  const [_, setSearchParams] = useUrlSearchParams<'page'>()

  return (
    <button
      disabled={props.disabled || props.loading || props.active}
      onClick={() => setSearchParams({page: props.page})}
      className={twMerge(
        Theme.button,
        props.loading && Theme.buttonLoading,
        props.active && Theme.buttonActive
      )}
    >
      {props.page}
    </button>
  )
}

function Etc(props: {visible: boolean}) {
  return props.visible ? <span className={twMerge(Theme.separator)}>...</span> : null
}
