
export const Theme = {
  container: 'flex items-center p-4 text-sm rounded-lg transition-opacity duration-300',
  icon: 'flex-shrink-0 inline me-3'
};

export const InfoSeverity = {
  info: {
    icon: 'fill-info-500',
    container: [
      'bg-info-25 text-gray-900',
      'dark:bg-info-800 dark:text-gray-300'
    ],
    bordered: [
      'border border-info-300',
      'dark:border-info-800'
    ]
  },
  danger: {
    icon: 'fill-danger-600',
    container: [
      'bg-danger-100 text-gray-700',
      'dark:bg-danger-800 dark:text-gray-300'
    ],
    bordered: [
      'border border-danger-300',
      'dark:border-danger-800'
    ]
  },
  success: {
    icon: 'fill-success-600',
    container: [
      'text-success-700 bg-success-50',
      'dark:bg-success-800 dark:text-success-400'
    ],
    bordered: [
      'border border-success-300',
      'dark:border-success-800'
    ]
  },
  warning: {
    icon: 'fill-warning-600',
    container: [
      'bg-warning-50 text-gray-700',
      'dark:bg-warning-800 dark:text-gray-300'
    ],
    bordered: [
      'border border-warning-300',
      'dark:border-warning-800'
    ]
  },
  gray: {
    icon: 'fill-gray-600',
    container: [
      'bg-gray-50 text-gray-800',
      'dark:bg-gray-800 dark:text-gray-300'
    ],
    bordered: [
      'border border-gray-300',
      'dark:border-gray-800'
    ]
  }
};
