import clsx from 'clsx'
import React from 'react'
import {useLocation} from 'react-router'
import {Link} from 'react-router-dom'
import {useMasterLayout} from 'src/app/context/MasterContext'
import {checkIsActive} from 'src/app/utils/router-helpers-utils'
import {KTSVG as Ktsvg} from '../../Libs/Icon/KTSVG'
import { twMerge } from 'tailwind-merge'

type Props = {
  children?: React.ReactNode
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  activePath?: string
  badge?: string
  badgeCircle?: boolean
}

const AsideMenuItem: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  activePath,
  badge,
  badgeCircle,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, activePath ?? to)
  const {minimize} = useMasterLayout()

  return (
    <div className='block p-0 text-fs-9'>
      <Link
        className={clsx(
          'p-3 mx-3 cursor-pointer flex flex-nowrap items-center rounded-lg transition duration-100',
          'hover:text-primary-light-2',
          {'bg-primary-600 text-primary-light-2 dark:bg-inverse-primary-light-3': isActive},
          {'text-neutral-50': !isActive},
          {truncate: minimize}
        )}
        to={to}
      >
        {icon && (
          <span className='flex items-center w-8 mr-2 shrink-0'>
            <Ktsvg path={icon} className='w-6 h-6' />
          </span>
        )}
        {/* still bootstrap style / change it !  */}
        {fontIcon && <i className={clsx('bi fs-3', fontIcon)}></i>}

        <span
        className={twMerge([
          "flex items-center flex-1 transition duration-300",
          minimize && 'opacity-0 group-hover:opacity-100',
          hasBullet && "ps-6"
        ])}
        >
          {title}
        </span>

        {/* still bootstrap style / change it !  */}
        {badge ? (
          <span
            className={clsx('badge badge-light-secondary', {'badge-circle': badgeCircle})}
            style={{minWidth: 23, minHeight: 23}}
          >
            {badge}
          </span>
        ) : null}
      </Link>
      {children}
    </div>
  )
}

export {AsideMenuItem}
