import { SVGProps } from "react";

export function XlsIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill="#369444"
        d="M12.187 16.25a.625.625 0 01-.625.625H9.375a.625.625 0 01-.625-.625v-4.375a.625.625 0 111.25 0v3.75h1.562a.625.625 0 01.625.625zm-4.949-4.884a.625.625 0 00-.872.146l-1.054 1.476-1.054-1.476a.625.625 0 10-1.015.726l1.301 1.825-1.303 1.824a.625.625 0 001.016.726l1.055-1.476 1.054 1.476a.625.625 0 001.016-.726L6.08 14.062l1.303-1.824a.625.625 0 00-.145-.872zm7.73 2.018c-.313-.091-.636-.184-.817-.3-.097-.064-.096-.079-.087-.149a.355.355 0 01.156-.287c.36-.243 1.199-.134 1.549-.043a.625.625 0 10.318-1.21c-.165-.043-1.641-.407-2.565.216a1.608 1.608 0 00-.7 1.168c-.156 1.24 1.067 1.594 1.797 1.805.943.273 1.025.385.999.593-.024.189-.099.26-.168.307-.36.24-1.185.121-1.527.028a.626.626 0 00-.335 1.207 4.74 4.74 0 001.187.156c.454 0 .96-.078 1.366-.348a1.626 1.626 0 00.717-1.19c.173-1.353-1.118-1.73-1.89-1.953zM3.125 8.75V3.125a1.25 1.25 0 011.25-1.25h7.5a.625.625 0 01.442.183l4.375 4.375a.626.626 0 01.183.442V8.75a.625.625 0 11-1.25 0V7.5h-3.75a.625.625 0 01-.625-.625v-3.75H4.375V8.75a.625.625 0 11-1.25 0zm9.375-2.5h2.24L12.5 4.009V6.25z"
      ></path>
    </svg>
  );
}
