import {
  twMerge
} from "tailwind-merge";
import {
  Children,
  createElement,
  isValidElement
} from "react";

import {
  HookFormProps
} from "./HookForm.types";

function HookForm({
  direction,
  className,
  children,
  onSubmit,
  form,
  gap,
  ...props
}: HookFormProps) {

  return (
    <form
      noValidate={true}
      onSubmit={form.handleSubmit(onSubmit)}
      className={twMerge(
        "flex w-full",
        `flex-${direction}`,
        `gap-${gap}`,
        className
      )}
      {...props}
    >
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          const {
            name,
            rule,
            ...rest
          } = child?.props;
          if (!name) {
            return (child);
          }
          const error = form.formState.errors[name];
          return createElement(child.type, {
            ...rest,
            ...form.register(name, rule),
            helperText: error?.message,
            required: !!rule?.required,
            error: !!error,
            key: child.key
          });
        }
      })}
    </form>
  );
}

HookForm.defaultProps = {
  direction: 'col',
  gap: 4
} as HookFormProps;

export { HookForm }