import { SVGProps } from "react";

export function PdfInputIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.6 28H10v2h1.6c.6 0 1-.4 1-1s-.4-1-1-1zM22 4H6v32h26V14L22 4zm-7.6 25.2c0 1.6-1.2 2.8-2.8 2.8H10v2H8v-8h3.6c1.6 0 2.8 1.2 2.8 2.8v.4zm8.2 1c0 2-1.6 3.8-3.8 3.8H16v-8h2.8c2 0 3.8 1.6 3.8 3.8v.4zM30 28h-4v2h3v2h-3v2h-2v-8h6v2zm0-4H8V6h14v8h8v10zm-11.2 4H18v4h.8c1.2 0 2-.8 2-2s-1-2-2-2z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
