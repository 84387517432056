import { SVGProps } from "react";

export function DangerIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <g clipPath="url(#clip0_3063_273)">
        <path
          fill="#DB3D4B"
          d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10zm0-15c.52 0 .938.418.938.938v4.375c0 .519-.418.937-.938.937a.935.935 0 01-.938-.938V5.939c0-.52.418-.938.938-.938zm1.25 8.75c0 .691-.559 1.25-1.25 1.25s-1.25-.559-1.25-1.25.559-1.25 1.25-1.25 1.25.559 1.25 1.25z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_3063_273">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
