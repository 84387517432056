import SwipeableViews from "react-swipeable-views";

import { twMerge } from "tailwind-merge";
import { Children, createElement, isValidElement } from "react";

import { SwipeableDialogContentProps } from "./SwipeableDialogContent.types";

export function SwipeableDialogContent({
  children,
  ...props
}: SwipeableDialogContentProps) {

  return (
    <SwipeableViews
      disableLazyLoading={false}
      disabled={true}
      {...props}
    >
      {Children.map(children, (child, index) => {
        if (isValidElement(child)) {
          return createElement(child.type, {
            ...child.props,
            key: child.key,
            className: twMerge(
              "transition-[max-height] overflow-hidden duration-600",
              props.index === index ? "max-h-[100vh]" : "max-h-0"
            )
          });
        }
        return (child);
      })}
    </SwipeableViews>
  );
}
