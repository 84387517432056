
export const Theme = {
  container: 'relative inline-flex items-center cursor-pointer',
  toggle: [
    'w-11 h-6 bg-gray-200 dark:peer-focus:ring-primary-800 rounded-full peer',
    'peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-200',
    'peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white peer-checked:bg-primary-600',
    "after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all",
    'dark:bg-gray-800 dark:border-gray-600'
  ],
  label: [
    'text-sm font-normal text-gray-900 ms-3',
    'dark:text-gray-300'
  ]
}
