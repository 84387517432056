import { useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import { DialogRouterProps } from "./DialogRouter.types";
import { Dialog } from "./Dialog.page";
import { DialogRef } from "./Dialog.types";

export function DialogRouter({
  onDismiss,
  path,
  ...props
}: DialogRouterProps) {

  const dialogRef = useRef<DialogRef>(null);
  const history = useHistory();

  const isOpen = path === history.location.pathname;

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.show();
    } else {
      dialogRef.current?.hide();
    }
  }, [isOpen]);

  const handleonDismiss = useCallback(() => {
    onDismiss?.();
    if (isOpen) {
      history.goBack();
    }
  }, [isOpen]);

  return (
    <Dialog
      onDismiss={handleonDismiss}
      ref={dialogRef}
      {...props}
    />
  );
}
