import { QueryClient } from '@tanstack/react-query'
import { FC, Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { LayoutProvider } from './context/LayoutContext'
import { LayoutSplashScreen } from './context/SplashScreenContext'
import AuthInit from './modules/auth/redux/AuthInit'
import { Routes } from './routers/Routes'
import { PersistQueryClientProvider, PersistQueryClientOptions } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { URL_GET_PROFILE } from './modules/user_and_permissions/services/User.service'
import { PageToast } from './components'
import { Refs } from './utils/refs-utils'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
})

const whitelistPersistQuery = [
  URL_GET_PROFILE
];

const persistOptions: Omit<PersistQueryClientOptions, 'queryClient'> = {
  dehydrateOptions: {
    shouldDehydrateQuery: (_) => _.queryKey.some((__) => whitelistPersistQuery.includes(__ as string)),
  },
  persister: createSyncStoragePersister({
    storage: window.localStorage,
    key: `query:${process.env.REACT_APP_STORAGE_KEY}`
  })
};

type Props = {
  basename?: string
}

const App: FC<Props> = ({ basename }) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename="/">
        <PersistQueryClientProvider
          client={queryClient}
          {...{ persistOptions }}
        >
          <LayoutProvider>
            <AuthInit>
              <Routes />
            </AuthInit>
            <PageToast ref={Refs.setPageToastRef} />
          </LayoutProvider>
        </PersistQueryClientProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export default App
