import { hasArray } from "src/app/utils/array-utils";

export function filterCount(value: {}) {
  try {

    const count = Object.values(value).reduce<number>((stack, item) => {
      const hasValueByType = ['boolean', 'number'].includes(typeof item);
      const hasStringValue = typeof item === 'string' && !!item;
      if (hasValueByType || hasStringValue || hasArray(item)) {
        return stack + 1;
      }
      return stack;
    }, 0);
    return count;

  } catch (error) {
    return 0;
  }
}

export function valueToString(data: { [key: string]: any }) {
  return Object.entries(data).reduce((stack, [key, value]) => {
    if (Array.isArray(value)) {
      return { ...stack, [key]: value.join('.') };
    }
    return { ...stack, [key]: value };
  }, {});
}
