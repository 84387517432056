import {MallModel} from '../../../../directory/models'
import {useHistory} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {useEffect, useRef, useState} from 'react'

import {useSafeFormDateRange} from 'src/app/hooks/safe-form-date-range'
import {useGetCampaignDetail, useSaveCampaign} from '../../../providers/Campaign.provider'
import {StoreCategoryModel, StoreModel} from 'src/app/modules/directory/models'
import {PaymentMethodModel} from 'src/app/modules/settings/models/PaymentMethod.model'
import {safeArray} from 'src/app/utils/array-utils'
import {useGetMasterStore} from 'src/app/modules/directory/providers/Store.provider'
import {useGetMasterStoreCategory} from 'src/app/modules/directory/providers/StoreCategory.provider'
import {PaymentCategoryModel} from '../../../../settings/models/PaymentCategory.model'
import {DialogRef} from '../../../../../components'

const defaultValues = {
  directory: {} as MallModel,
  merchant_categories: [] as StoreCategoryModel[],
  merchants: [] as StoreModel[],
  payment_methods: [] as PaymentMethodModel[],
  payment_categories: [] as PaymentCategoryModel[],
  start_date: '',
  end_date: '',
  campaign_type: '',
  time_type: '',
  start_time: '',
  end_time: '',
}

export function useCampaignFormHooks() {
  const saveCampaign = useSaveCampaign()
  const campaign = useGetCampaignDetail()
  const history = useHistory()
  const form = useForm({defaultValues})
  const campaignValidateDialogRef = useRef<DialogRef>(null)
  const [campaignValidateDataDialog, setCampaignValidateDataDialog] = useState<any>(null)
  const [campaignValidateAlertMessage, setCampaignValidateAlertMessage] = useState<any>(null)

  const directory = form.watch('directory')
  const storeCategories = form.watch('merchant_categories')
  const store = useGetMasterStore({
    directory_ids: !directory?.id ? undefined : [directory?.id],
    limit: 1000,
    page: 1,
    statuses: ['ACTIVE'],
  })

  const storeCategory = useGetMasterStoreCategory({
    limit: 1000,
    page: 1,
    statuses: ['ACTIVE'],
  })

  const minPeriod = useSafeFormDateRange({
    startDateKey: 'start_date',
    endDateKey: 'end_date',
    form,
  })

  const handleSubmit = form.handleSubmit(
    async ({merchant_categories, payment_methods, directory, merchants, payment_categories, ...formData}) => {
      try {
        const payload = {
          ...formData,
          merchant_category_ids: merchant_categories.map((_) => _.value || _.id),
          payment_category_ids: payment_categories.map((_) => _.value || _.id),
          payment_method_ids: payment_methods.map((_) => _.value || _.id),
          merchant_ids: merchants.map((_) => _.value || _.id),
          directory_id: directory.id,
          status: undefined,
        }
        const result: any = await saveCampaign.mutateAsync(payload)
        if (result.data.response_schema?.response_code === 'PAKUWON-CAMPAIGNSMODULE-406') {
          campaignValidateDialogRef.current?.show()
          setCampaignValidateDataDialog(result.data.response_output?.list.content)
          setCampaignValidateAlertMessage(result.data.response_schema?.response_message.en)
        } else {
          history.goBack()
        }
      } catch (error) {
        console.log('🚀 ~ useCampaignFormHooks ~ error:', error)
      }
    },
  )

  // sync merchant_category to merchant field
  const handleSyncStoreCategory = (value: StoreCategoryModel | StoreCategoryModel[]) => {
    if (Array.isArray(value)) {
      const isNewValue = !storeCategories?.length || storeCategories?.length < value.length

      const stores = safeArray(form.getValues('merchants'))
      if (isNewValue) {
        const lastItem = value?.at(-1)
        const masterStores = store.data?.content?.filter(
          (_) => _.merchant_category_id === lastItem?.id,
        )
        const mergedStores = safeArray(masterStores).reduce((stack, item) => {
          const isExist = stack.some((_) => _.id === item.id)
          return isExist ? stack : [...stack, item]
        }, stores)

        form.setValue('merchants', mergedStores)
      } else {
        const syncStores = stores.reduce<StoreModel[]>((stack, item) => {
          const isExists = value?.some((_) => _.id === item.merchant_category_id)
          return isExists ? [...stack, item] : stack
        }, [])
        form.setValue('merchants', syncStores)
      }
    }
  }

  const handleSyncStore = (value: StoreModel | StoreModel[]) => {
    if (Array.isArray(value)) {
      const syncStoreCategories = value.reduce<StoreCategoryModel[]>((stack, item) => {
        const isExists = stack.some((_) => _.id === item.merchant_category_id)
        return isExists || !item.merchant_category ? stack : [...stack, item.merchant_category]
      }, [])
      form.setValue('merchant_categories', syncStoreCategories)
    }
  }

  useEffect(() => {
    if (form.formState.touchedFields?.directory) {
      form.setValue('merchant_categories', [])
      form.setValue('merchants', [])
    }
  }, [directory?.id])

  return {
    action: {handleSubmit, handleSyncStore, handleSyncStoreCategory},
    state: {storeCategories, directory, minPeriod, campaignValidateDataDialog, campaignValidateAlertMessage},
    campaignValidateDialogRef,
    saveCampaign,
    campaign,
    form,
    store,
    storeCategory,
  }
}
