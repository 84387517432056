import { TypographyVariants, Theme as Typography } from "../Typography/Typography.theme";

export const Theme = {
  wrapper: [
    Typography.typography,
    TypographyVariants.body1,
    "[&_ul]:list-disc [&_ul]:pl-4",
    "[&_ol]:list-decimal [&_ol]:pl-4",
    "whitespace-pre-wrap"
  ],
  
};