import React from 'react'
import { Switch } from 'react-router-dom'
import ProtectedRoute from 'src/app/routers/ProtectedRoute'
import AuditTrailScreens from './AuditTrailScreen'
import AuditTrailPage from './page/AuditTrail.page'

const AuditTrailRoutes: React.FC = () => {
  return (
    <Switch>
      <ProtectedRoute
        path={AuditTrailScreens.LIST_AUDIT_TRAIL.PATH}
        screen={AuditTrailScreens.LIST_AUDIT_TRAIL}
        component={AuditTrailPage}
        exact
      />
      <ProtectedRoute
        path={AuditTrailScreens.DETAIL_AUDIT_TRAIL.PATH}
        screen={AuditTrailScreens.DETAIL_AUDIT_TRAIL}
        component={AuditTrailPage}
        exact
      />

    </Switch>
  )
}

export default AuditTrailRoutes;
