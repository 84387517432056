import { SVGProps } from "react";

export function SuccessIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm4.414-11.836l-5 5a.934.934 0 01-1.324 0l-2.5-2.5a.937.937 0 011.324-1.324l1.836 1.836 4.336-4.34a.937.937 0 011.324 1.324l.004.004z"
      ></path>
    </svg>
  );
}
