
export function ScrollIntoContent() {

  const handleScrollIntoContent = (ref: HTMLDivElement) => {
    if (ref !== null) {
      ref.scrollIntoView();
    }
  };

  return <div ref={handleScrollIntoContent} />
}
