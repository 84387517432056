import {
  ChipStatus,
  DialogContent,
  DialogTitle,
  Typography,
} from 'src/app/components'
import GIcon from '../../../../components/Libs/Icon/GIcon'
import {useParams} from 'react-router-dom'
import {useDialogContentAuditTrailDetail} from './DialogContentAuditTrailDetail.hook'
import dayjs from 'dayjs'

type DialogContentAuditTrailDetailProps = {}

export function DialogContentAuditTrailDetail(props: DialogContentAuditTrailDetailProps) {
  const {auditTrailDetail} = useDialogContentAuditTrailDetail()
  const dataDetail = auditTrailDetail?.data
  const params: {user_action: string; id: string} = useParams()

  // Generate action name based on params.user_action using switch case
  const generateActionName = () => {
    if (!params.user_action) return 'Unknown Action'
    const action = params.user_action.toLowerCase()

    switch (true) {
      case action.includes('login'):
        return 'login'
      case action.includes('logout'):
        return 'logout'
      case action.includes('suspend'):
        return 'suspended audit trail'
      case action.includes('delete'):
        return 'deleted audit trail'
      case action.includes('add'):
        return 'added'
      case action.includes('edit'):
        return 'edited'
      case action.includes('reactive'):
        return 'reactivate'
      case action.includes('approve'):
        return 'approved'
      case action.includes('void'):
        return 'voided audit trail'
      case action.includes('buy'):
        return 'buy audit trail'
      default:
        return 'Unknown Action'
    }
  }

  // Utility function to convert field names to a more readable format
  const convertFieldName = (fieldName: string | undefined | null) => {
    if (!fieldName || typeof fieldName !== 'string') return 'Unknown Field' // Safeguard against empty/undefined field names
    return fieldName
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize the first letter of each word
  }

  const merchantImageSplitter = (data: string) => {
    const splitData = data.split(',')
    return splitData.map((item: string) => {
      return (
        <img src={item} alt={item} className="h-12 w-12 rounded object-contain border" />
      )
    })
  }

  // List of labels considered as images
  const imageLabels = [
    'Image', 'Promo Banner Image', 'Promo Banner Thumbnail Image', 'Reward Banner Image',
    'Thumbnail Image', 'Icon', 'Banner Image', 'Merchant Logo', 'Promo Photo',
    'Reward Photo', 'Id Photo', 'Banner Photo', 'Snap Receipt Photo', 'Merchant Image', 'Identity Image',
    'Tagline Image', 'Reward Banner Thumbnail Image', 'Logo', 'Transaction Image', 'Transaction Thumbnail Image',
    'Profile Image', 'Thumbnail Image', 'Transaction Images',
  ]

  // Check if the label is an image type
  const isImageLabel = (label: string) => imageLabels.includes(label)

  const renderImage = (src: string, alt: string) => (
    <img src={src} alt={alt} className="h-12 w-12 rounded object-contain border" />
  )

  const renderDataDetail = (label: string, oldValue: string, currentValue: string) => {
    oldValue = oldValue === 'null' || oldValue === null ? '-' : oldValue
    currentValue = currentValue === 'null' || currentValue === null ? '-' : currentValue

    // Replace 'true' and 'false' with 'yes' and 'no'
    oldValue = oldValue === 'true' ? 'Yes' : oldValue === 'false' ? 'No' : oldValue
    currentValue = currentValue === 'true' ? 'Yes' : currentValue === 'false' ? 'No' : currentValue

    // List of labels considered as dates
    const dateLabels = [
      'Voided Date',
      'Claimed Date',
      'Used Date',
      'Expired Date',
      'Reactive Date',
      'Transaction Date',
      'Rejected Date',
      'Approved Date',
      'Connected At',
      'Blocked Time',
      'Suspend Date',
      'Last Transaction Date',
      'Schedule Date',
      'Used At',
      'Verified At',
      'Created At',
      'Updated At',
    ]

    // Check if the field is 'updated_at' or 'created_at', and format the values using dayjs
    const shouldFormatAsDate = (label: string) => dateLabels.includes(label)
    const isValidDate = (value: string) => dayjs(value).isValid()

    if (shouldFormatAsDate(label)) {
      if (isValidDate(oldValue)) {
        oldValue = dayjs(oldValue).format('YYYY/MM/DD HH:mm:ss')
      }
      if (isValidDate(currentValue)) {
        currentValue = dayjs(currentValue).format('YYYY/MM/DD HH:mm:ss')
      }
    }


    return (
      <div>
        {/* Login/Logout condition */}
        {(params.user_action.toLowerCase().includes('login') || params.user_action.toLowerCase().includes('logout')) && (
          <>
            <Typography variant="caption">{label}</Typography>
            <Typography dangerouslySetInnerHTML={{__html: currentValue}} />
          </>
        )}

        {/* Edit condition */}
        {(params.user_action.toLowerCase().includes('edit') || params.user_action.toLowerCase().includes('void') || params.user_action.toLowerCase().includes('buy') || params.user_action.toLowerCase().includes('reactive') || params.user_action.toLowerCase().includes('suspend') || params.user_action.toLowerCase().includes('approve') || params.user_action.toLowerCase().includes('reject')) && (
          <div  className="grid grid-cols-3 gap-2 p-2">
            <Typography variant="caption">{label}</Typography>
            <Typography className={`flex items-center gap-2 col-span-2`}>
              {isImageLabel(label) ? (
                <>
                  {oldValue !== '-' && (
                    <>
                      {label === 'Merchant Image' || label === 'Transaction Images' ? (
                        <div className="grid grid-cols-8 gap-2">
                          {merchantImageSplitter(oldValue)}
                        </div>
                      ) : (
                        renderImage(oldValue, label)
                      )}
                      <GIcon icon="IconArrowRight" />
                    </>
                  )}
                  {label === 'Merchant Image' || label === 'Transaction Images' ? (
                    <div className="grid grid-cols-8 gap-2">
                      {merchantImageSplitter(currentValue)}
                    </div>
                  ) : (
                    renderImage(currentValue, label)
                  )}
                </>
              ) : (
                <>
                  {label === 'Status' || label === 'Identity Status' || label === 'Reward Status' || label === 'Customer Remarks' || label === 'Promo Status' ? (
                    <div className="flex items-center">
                      {oldValue !== '-' && (
                        <div className="flex items-center">
                          <ChipStatus state={oldValue} />
                          <GIcon icon="IconArrowRight" className="mx-2" />
                        </div>
                      )}
                      <ChipStatus state={currentValue} />
                    </div>
                  ) : (
                    <div className="flex">
                      {oldValue !== '-' && (
                        <div className="flex">
                          <div className={`relative group`}>
                            <p dangerouslySetInnerHTML={{__html: oldValue}}
                               className={`whitespace-pre-wrap break-words text-xs}`} />
                          </div>
                          <div className="flex items-center">
                            <GIcon icon="IconArrowRight" className="mx-2" />
                          </div>
                        </div>
                      )}
                      <div className={`relative group`}>
                        <p
                          dangerouslySetInnerHTML={{__html: currentValue}}
                          className={`whitespace-pre-wrap break-words text-xs`}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </Typography>
          </div>
        )}

        {/* Delete condition */}
        {params.user_action.toLowerCase().includes('delete') && (
          <>
            <Typography variant="caption">{label}</Typography>
            {isImageLabel(label) ? (
              <>
                {label === 'Merchant Image' || label === 'Transaction Images' ? (
                  <div className="grid grid-cols-8 gap-2">
                    {merchantImageSplitter(oldValue)}
                  </div>
                ) : (
                  renderImage(oldValue, label)
                )}
              </>
            ) : (
              <>
                {label === 'Status' || label === 'Identity Status' || label === 'Reward Status' || label === 'Customer Remarks' || label === 'Promo Status' ? (
                  <div>
                  <ChipStatus state={oldValue} />
                  </div>
                ) : (
                  <Typography dangerouslySetInnerHTML={{__html: oldValue}} className="whitespace-pre-wrap break-words text-xs"/>
                )}
              </>
            )}
          </>
        )}

        {/* Add condition */}
        {(params.user_action.toLowerCase().includes('add'))  && (
          <div  className="grid grid-cols-3 gap-2 p-2">
            <Typography variant="caption">{label}</Typography>
            {isImageLabel(label) ? (
              <div className="col-span-2">
                {label === 'Merchant Image' || label === 'Transaction Images' ? (
                  <div className="grid grid-cols-8 gap-2">
                    {merchantImageSplitter(currentValue)}
                  </div>
                ) : (
                  renderImage(currentValue, label)
                )}
              </div>
            ) : (
              <div className="col-span-2">
                {label === 'Status' || label === 'Identity Status' || label === 'Reward Status' || label === 'Customer Remarks' || label === 'Promo Status' ? (
                  <div className="flex items-center">
                    {oldValue !== '-' && (
                        <div className="flex items-center">
                          <ChipStatus state={oldValue} />
                          <GIcon icon="IconArrowRight" className="mx-2" />
                        </div>
                      )}
                    <ChipStatus state={currentValue} />
                  </div>
                ) : (
                  <div className={`relative group`}>
                    <p
                      dangerouslySetInnerHTML={{__html: currentValue}}
                      className={`whitespace-pre-wrap break-words text-xs`}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  return (
    <div>
      <DialogTitle title="Audit Trail Details" withStatuses={true} contentStatuses={generateActionName()} />
      <DialogContent>
        <div>
          {dataDetail?.changes.map((change: any, index: number) => {
            const source = change.currentValue || change.oldValue

            return (
              source &&
              (
                <div key={index}>
                  {Object.keys(source)
                    .filter(field => !['id', 'directory_id', 'merchant_category_ids'].includes(field))
                    .map((field) => {
                      const oldValue = change.oldValue?.[field] || '-'
                      const currentValue = change.currentValue?.[field] || '-'

                      return (
                        <div key={`${field}-${index}`}>
                          {renderDataDetail(convertFieldName(field), oldValue, currentValue)}
                        </div>
                      )
                    })
                  }

                  {/* Add separator (hr) after each object */}
                  {index < dataDetail.changes.length - 1 && <hr className="my-4" />}
                </div>
              )
            )
          })}
        </div>
      </DialogContent>
    </div>
  )
}