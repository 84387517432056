export function FallbackView() {
  return (
    <div
      data-testid='fallback-view-component'
      className='d-flex flex-column align-items-center justify-content-center w-100 h-100'
    >
      <img
        className='mb-4'
        src={'/media/logos/logo.png'}
        alt='Pakuwon'
        style={{height: 80}}
      />
    </div>
  )
}
