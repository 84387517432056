import { createContext, useMemo } from "react";
import { twMerge } from "tailwind-merge";

import { TableContextValue, TableProps } from "./Table.types";
import { Theme } from "./Table.theme";

const tableContextDefaultValue: TableContextValue = {}
export const TableContext = createContext<TableContextValue>(tableContextDefaultValue);

export function Table({
  classNames,
  className,
  ...props
}: TableProps) {

  const value = useMemo(() => ({ variant: 'bordered' }), []);

  return (
    <TableContext.Provider value={value}>
      <div className={twMerge("w-full overflow-auto pb-5 custom-scrollbar mb-3", classNames?.container)}>
        <table className={twMerge(Theme.table, classNames?.table, className)} {...props} />
      </div>
    </TableContext.Provider>
  );
}
