import {defaultBreadcrumbs, generateBreadcrumb} from 'src/app/models/screen-prop'

const UserAndPermissionsScreens = {
  USER_AND_PERMISSIONS: {
    PATH: '/user-and-permissions',
    TITLE: 'User & Permissions',
    TITLE_ALT: 'User & Permissions',
  },
  LIST_USER: {
    PATH: '/user-and-permissions/user/index',
    TITLE: 'User',
    TITLE_ALT: 'Users List',
    PERMISSION: 'manage_user.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_USER: {
    PATH: '/user-and-permissions/user/add',
    TITLE: 'Add',
    TITLE_ALT: 'Add User',
    PERMISSION: 'manage_user.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_USER: {
    PATH: '/user-and-permissions/user/edit/:id',
    TITLE: 'Edit',
    TITLE_ALT: 'Edit User',
    PERMISSION: 'manage_user.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_USER: {
    PATH: '/user-and-permissions/user/detail/:id',
    TITLE: 'Detail',
    TITLE_ALT: 'Detail User',
    PERMISSION: 'manage_user.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_ROLE: {
    PATH: '/user-and-permissions/role/index',
    TITLE: 'Role',
    TITLE_ALT: 'List Role',
    PERMISSION: 'manage_role.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_ROLE: {
    PATH: '/user-and-permissions/role/list/add',
    TITLE: 'Add',
    TITLE_ALT: 'Add Role',
    PERMISSION: 'manage_role.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_ROLE: {
    PATH: '/user-and-permissions/role/edit/:id',
    TITLE: 'Edit',
    TITLE_ALT: 'Edit Role',
    PERMISSION: 'manage_role.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_ROLE: {
    PATH: '/user-and-permissions/role/detail/:id',
    TITLE: 'Detail',
    TITLE_ALT: 'Detail Role',
    PERMISSION: 'manage_role.read',
    breadcrumbs: defaultBreadcrumbs,
  },
}

const userBreadcrumbs = generateBreadcrumb([UserAndPermissionsScreens.LIST_USER])
const roleBreadcrumbs = generateBreadcrumb([UserAndPermissionsScreens.LIST_ROLE])

UserAndPermissionsScreens.ADD_USER.breadcrumbs = () => userBreadcrumbs
UserAndPermissionsScreens.EDIT_USER.breadcrumbs = () => userBreadcrumbs
UserAndPermissionsScreens.DETAIL_USER.breadcrumbs = () => userBreadcrumbs
UserAndPermissionsScreens.ADD_ROLE.breadcrumbs = () => roleBreadcrumbs
UserAndPermissionsScreens.EDIT_ROLE.breadcrumbs = () => roleBreadcrumbs
UserAndPermissionsScreens.DETAIL_ROLE.breadcrumbs = () => roleBreadcrumbs

export default UserAndPermissionsScreens
