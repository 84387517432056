import { useGetCampaign } from "../../providers/Campaign.provider";

export function useCampaignHistoryHooks() {

  const campaign = useGetCampaign({ statuses: ['INACTIVE'] });

  return {
    campaign
  };
}
