import {
  ChipStatus,
  DialogContent,
  DialogTitle,
  Typography,
} from 'src/app/components'
import GIcon from '../../../../components/Libs/Icon/GIcon'
import {useParams} from 'react-router-dom'
import {useDialogContentAuditTrailDetail} from './DialogContentAuditTrailDetail.hook'

type DialogContentAuditTrailDetailProps = {}

export function DialogContentAuditTrailDetail(props: DialogContentAuditTrailDetailProps) {
  const {auditTrailDetail} = useDialogContentAuditTrailDetail()
  const dataDetail = auditTrailDetail?.data
  const params: {user_action: string; id: string} = useParams()

  // Generate action name based on params.user_action using switch case
  const generateActionName = () => {
    if (!params.user_action) return 'Unknown Action'
    const action = params.user_action.toLowerCase()

    switch (true) {
      case action.includes('login'):
        return 'login'
      case action.includes('logout'):
        return 'logout'
      case action.includes('suspend'):
        return 'suspended audit trail'
      case action.includes('delete'):
        return 'deleted audit trail'
      case action.includes('add'):
        return 'added'
      case action.includes('edit'):
        return 'edited'
      case action.includes('reactive'):
        return 'reactivate'
      case action.includes('approve'):
        return 'approved'
      default:
        return 'Unknown Action'
    }
  }

  // Utility function to convert field names to a more readable format
  const convertFieldName = (fieldName: string | undefined | null) => {
    if (!fieldName || typeof fieldName !== 'string') return 'Unknown Field' // Safeguard against empty/undefined field names
    return fieldName
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize the first letter of each word
  }

  const truncateText = (text: string, charLimit: number) => {
    if (text.length > charLimit) {
      return {truncatedText: text.substring(0, charLimit) + '...', isTruncated: true}
    }
    if (text.includes('_')) {
      return {truncatedText: text.replace(/_/g, ' '), isTruncated: false}
    }
    return {truncatedText: text, isTruncated: false}
  }

  const merchantImageSplitter = (data: string) => {
    const splitData = data.split(',')
    return splitData.map((item: string) => {
      return (
        <img src={item} alt={item} className="h-12 w-12 rounded object-contain border" />
      )
    })
  }

  // List of labels considered as images
  const imageLabels = [
    'Image', 'Promo Banner Image', 'Promo Banner Thumbnail Image', 'Reward Banner Image',
    'Thumbnail Image', 'Icon', 'Banner Image', 'Merchant Logo', 'Promo Photo',
    'Reward Photo', 'Id Photo', 'Banner Photo', 'Snap Receipt Photo', 'Merchant Image',
  ]

  // Check if the label is an image type
  const isImageLabel = (label: string) => imageLabels.includes(label)

  const renderImage = (src: string, alt: string) => (
    <img src={src} alt={alt} className="h-12 w-12 rounded object-contain border" />
  )

  const renderDataDetail = (label: string, oldValue: string, currentValue: string) => {
    oldValue = oldValue === 'null' || oldValue === null ? '-' : oldValue
    currentValue = currentValue === 'null' || currentValue === null ? '-' : currentValue

    const {truncatedText: truncatedOldValue, isTruncated: isOldValueTruncated} = truncateText(oldValue, 90)
    const {truncatedText: truncatedCurrentValue, isTruncated: isCurrentValueTruncated} = truncateText(currentValue, 90)

    return (
      <div>
        {/* Login/Logout condition */}
        {(params.user_action.toLowerCase().includes('login') || params.user_action.toLowerCase().includes('logout')) && (
          <>
            <Typography variant="caption">{label}</Typography>
            <Typography dangerouslySetInnerHTML={{__html: truncatedCurrentValue}} />
          </>
        )}

        {/* Edit condition */}
        {params.user_action.toLowerCase().includes('edit') && (
          <>
            <Typography variant="caption">{label}</Typography>
            <Typography className="flex items-center gap-2">
              {isImageLabel(label) ? (
                <>
                  {oldValue !== '-' && (
                    <>
                      {renderImage(oldValue, label)}
                      <GIcon icon="IconArrowRight" />
                    </>
                  )}
                  {renderImage(currentValue, label)}
                </>
              ) : (
                <div className="flex">
                  {oldValue !== '-' && (
                    <div className="flex">
                      <div className="relative group">
                        <p dangerouslySetInnerHTML={{__html: truncatedOldValue}}
                           className="text-clip w-36 whitespace-pre-wrap break-words text-xs" />
                        {/* Full text on hover */}
                        {isOldValueTruncated && (
                          <div
                            className="absolute hidden group-hover:block bg-gray-700 text-white p-2 rounded shadow-lg z-10 w-72 whitespace-pre-wrap break-words text-xs">
                            <span dangerouslySetInnerHTML={{__html: oldValue}} />
                          </div>
                        )}
                      </div>
                      <div className="flex items-center">
                        <GIcon icon="IconArrowRight" className="mr-4" />
                      </div>
                    </div>
                  )}
                  <div className="relative group">
                    <p
                      dangerouslySetInnerHTML={{__html: truncatedCurrentValue}}
                      className="text-clip w-36 text-xs"
                    />
                    {/* Full text on hover */}
                    {isCurrentValueTruncated && (
                      <div
                        className="absolute hidden group-hover:block bg-gray-700 text-white p-2 rounded shadow-lg z-10 w-72 whitespace-pre-wrap break-words text-xs"
                      >
                        <span dangerouslySetInnerHTML={{__html: currentValue}} />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Typography>
          </>
        )}

        {/* Delete condition */}
        {params.user_action.toLowerCase().includes('delete') && (
          <>
            <Typography variant="caption">{label}</Typography>
            {isImageLabel(label) ? (
              renderImage(currentValue, label)
            ) : (
              <Typography dangerouslySetInnerHTML={{__html: truncatedOldValue}} />
            )}
          </>
        )}

        {/* Add condition */}
        {(params.user_action.toLowerCase().includes('add') || params.user_action.toLowerCase().includes('reactive') || params.user_action.toLowerCase().includes('suspend') || params.user_action.toLowerCase().includes('approve') || params.user_action.toLowerCase().includes('reject') || params.user_action.toLowerCase().includes('void')) && (
          <>
            <Typography variant="caption">{label}</Typography>
            {isImageLabel(label) ? (
              <>
                {label === 'Merchant Image' ? (
                  <div className="flex gap-1">
                    {merchantImageSplitter(currentValue)}
                  </div>
                ) : (
                  renderImage(currentValue, label)
                )}
              </>
            ) : (
              <>
                {label === 'Status' ? (
                  <ChipStatus state={currentValue} />
                ) : (
                  <div className="relative group">
                    <p
                      dangerouslySetInnerHTML={{__html: truncatedCurrentValue}}
                      className="text-clip w-36 text-xs"
                    />
                    {/* Full text on hover */
                    }
                    {
                      isCurrentValueTruncated && (
                        <div
                          className="absolute hidden group-hover:block bg-gray-700 text-white p-2 rounded shadow-lg z-10 w-72 whitespace-pre-wrap break-words text-xs"
                        >
                          <span dangerouslySetInnerHTML={{__html: currentValue}} />
                        </div>
                      )
                    }
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    )
  }

  return (
    <div>
      <DialogTitle title="Audit Trail Details" withStatuses={true} contentStatuses={generateActionName()} />
      <DialogContent>
        <div className="grid grid-cols-2 gap-2 p-4">
          {dataDetail?.changes.map((change: any, index: number) => {
            const source = change.currentValue || change.oldValue

            return (
              source &&
              Object.keys(source)
                // Filter out the unwanted fields
                .filter(field => !['id', 'directory_id', 'merchant_category_ids'].includes(field))
                .map((field) => {
                  const oldValue = change.oldValue?.[field] || '-'
                  const currentValue = change.currentValue?.[field] || '-'

                  return (
                    <div key={`${field}-${index}`}>
                      {renderDataDetail(convertFieldName(field), oldValue, currentValue)}
                    </div>
                  )
                })
            )
          })}
        </div>
      </DialogContent>
    </div>
  )
}