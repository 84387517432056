const ReceiptReportScreens = {
    RECEIPT_REPORT: {
      PATH: '/receipt-report',
      TITLE: 'Receipt Report',
      TITLE_ALT: 'Receipt Report',
      PERMISSION: 'snap_receipt_rule.read'
    },
    LIST_RECEIPT_REPORT: {
      PATH: '/receipt-report/index',
      TITLE: 'List Receipt',
      TITLE_ALT: 'List',
      PERMISSION: 'snap_receipt_rule.read'
    },
    DETAIL_RECEIPT_REPORT: {
      PATH: '/receipt-report/detail/:id',
      TITLE: 'Detail Receipt',
      TITLE_ALT: 'Detail',
      PERMISSION: 'snap_receipt_rule.read'
    },
    EDIT_RECEIPT_REPORT: {
      PATH: '/receipt-report/edit/:id',
      TITLE: 'Edit Receipt',
      TITLE_ALT: 'Edit',
      PERMISSION: 'snap_receipt_rule.update'
    },
  }
  
  export default ReceiptReportScreens;
  