import axios, { AxiosError } from 'axios'
import { BaseResponse } from 'src/app/models/api.types'

export interface ErrorMessageResult {
  en?: string
  id?: string
}

export function getErrorMessage(
  error: AxiosError<BaseResponse<any>> | unknown,
  withProps: boolean = false,
  defaultValue: ErrorMessageResult | null = {}
): ErrorMessageResult {
  try {
    if (withProps) {
      return axios.isAxiosError(error)
        ? error?.response?.data?.response_output?.errors?.find(() => true)?.message ??
        defaultValue ??
        error?.response?.data?.response_schema?.response_message
        : undefined
    } else {
      return axios.isAxiosError(error)
        ? error?.response?.data?.response_schema?.response_message
        : undefined
    }
  } catch (e) { }
  return {}
}

export interface ApiErrors {
  generic: string
  code: string
  detailed: Record<string, string>
}

export function getErrors(error: AxiosError<BaseResponse<any>> | unknown): ApiErrors {
  const detailed: Record<string, string> = {}
  const axiosResponse = axios.isAxiosError(error) ? error?.response : undefined
  try {
    if (axiosResponse)
      axiosResponse?.data?.response_output?.errors?.forEach((it: any) => {
        const message = it.message
        if (message) detailed[it.field] = message
      })
  } catch (e) { }
  return {
    generic: axiosResponse?.data?.response_schema?.response_message ?? String(error),
    code: axiosResponse?.data?.response_schema?.response_code ?? String(axiosResponse?.status),
    detailed,
  }
}

export interface ErrorFieldConfig {
  include?: string[]
  exclude?: string[]
  mapper?: Record<string, string>
}

export function handleErrorGeneric(
  error: AxiosError<BaseResponse<any>> | unknown,
  setFieldError: (fieldName: string, error: string) => any,
  fallback: (text: ApiErrors) => any,
  { include = [], exclude = [], mapper = {} }: ErrorFieldConfig = {}
) {
  const errors = getErrors(error)
  let entries = Object.entries(errors.detailed)
  if (include.length > 0) {
    entries = entries.filter((it) => include.indexOf(it[0]) > -1)
  }
  if (exclude.length > 0) {
    entries = entries.filter((it) => exclude.indexOf(it[0]) < 0)
  }
  if (entries.length > 0) {
    entries.forEach((it) => setFieldError(mapper[it[0]] ?? it[0], it[1]))
  } else {
    fallback(errors)
  }
}

export function getSearchParamsFullDate (month?: string, year?: string) {
  if (!month) month = ""
  if (!year) month = ""
  const fullDate = [year, month?.padStart(2, '0'), "01"].join("-")
  const date = month && year ? fullDate : undefined
  return date
}
