import { twMerge } from "tailwind-merge";
import { Theme } from "./MenuItem.theme";
import { MenuItemProps } from "./MenuItem.types";
import { createElement } from "react";

export function MenuItem({
  className,
  startIcon,
  children,
  ...props
}: MenuItemProps) {

  return (
    <span className={twMerge(Theme.container, className)} {...props}>
      {(!!startIcon) && createElement(startIcon, { className: twMerge(Theme.startIcon) })}
      {children}
    </span>
  );
}
