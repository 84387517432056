import { twMerge } from "tailwind-merge";
import { ChipVariants, Theme } from "./Chip.theme";
import { ChipProps } from "./Chip.types";

export function Chip({
  className,
  variant,
  ...props
}: ChipProps) {

  return (
    <span
      className={twMerge(
        Theme.chip,
        !!variant && ChipVariants[variant],
        className
      )}
      {...props}
    />
  );
}
