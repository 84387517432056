import { twMerge } from "tailwind-merge";

import { Stack } from "../../Layouts";
import { StackProps } from "../../Layouts/Stack/Stack.types";

function MenuContent({
  className,
  ...props
}: StackProps) {

  return (
    <Stack className={twMerge("px-6 py-4 max-h-[300px] overflow-auto w-[400px]", className)} {...props} />
  );
}

MenuContent.defaultProps = {
  direction: 'col'
} as StackProps;

export { MenuContent }
