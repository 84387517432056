import {twMerge} from 'tailwind-merge'
import {MouseEvent, useEffect, useRef, useState} from 'react'

import {Theme} from './UnderlineTabs.theme'
import {UnderlineTabsItemType, UnderlineTabsProps} from './UnderlineTabs.type'
import {UnderlineTab} from './UnderlineTab.page'

export function UnderlineTabs<I extends UnderlineTabsItemType>({
  defaultValue = 0,
  className,
  children,
  onChange,
  items,
  index = 0,
  title,
  ...props
}: UnderlineTabsProps<I>) {
  const [underlineStyle, _setUnderlineStyle] = useState<any>(null)

  const itemRefs = useRef<{[key: string]: HTMLSpanElement | null}>({})

  useEffect(() => {
    setUnderlineStyle(itemRefs.current[index])
  }, [index])

  const setUnderlineStyle = (element: HTMLSpanElement | null) => {
    const {offsetWidth, offsetLeft} = element || {}
    _setUnderlineStyle({
      width: offsetWidth,
      left: offsetLeft,
    })
  }

  const handleClick = (item: I, _index: number) => (event: MouseEvent<HTMLSpanElement>) => {
    setUnderlineStyle(event.currentTarget)
    onChange?.(item, _index)
  }

  const renderItem = (item: I, _index: number) => {
    return (
      <UnderlineTab
        onClick={handleClick(item, _index)}
        active={index === _index}
        ref={(_) => {
          itemRefs.current[_index] = _
        }}
        key={item.label}
      >
        {item.label}
      </UnderlineTab>
    )
  }

  return (
    <div className={twMerge(Theme.container, className)} {...props}>
      {title && (
        <div className='flex items-center flex-1'>
          <h1 className='text-xl font-semibold text-gray-900'>{title}</h1>
        </div>
      )}
      {Array.isArray(items) && items.map(renderItem)}
      <span className={twMerge(Theme.underline)} style={underlineStyle} />
    </div>
  )
}
