
export const Theme= {
  container: [
    'grid grid-cols-3 py-3 border-b border-gray-100',
    'dark:border-gray-700'
  ],
  label: [
    'text-gray-500',
    'dark:text-gray-500'
  ]
};
