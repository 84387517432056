import { PageToastRef } from "../components";

export class RefsUtils {

  pageToast: PageToastRef = {} as PageToastRef;

  setPageToastRef = (ref: PageToastRef) => {
    this.pageToast = ref;
  };
}

export const Refs = new RefsUtils();
