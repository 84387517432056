import { PageTabsData } from "src/app/components";
import CampaignScreens from "../../CampaignScreens";

import { Campaign } from "../Campaign/Campaign.page";
import { CampaignHistory } from "../Campaign/CampaignHistory.page";
import { CampaignTabsPath } from "./CampaignTabs.types";

export const campaignTabs: PageTabsData<CampaignTabsPath>[] = [
  {
    permission: 'campaign.read',
    component: Campaign,
    label: 'Active Campaign',
    title: CampaignScreens.LIST_CAMPAIGN.TITLE,
    path: 'index'
  },
  {
    permission: 'campaign.read',
    component: CampaignHistory,
    label: 'History Campaign',
    title: CampaignScreens.LIST_CAMPAIGN_HISTORY.TITLE,
    path: 'history'
  }
];
