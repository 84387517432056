
import {
  EmailIcon,
  PasswordIcon
} from "src/app/components/Icons/InputIcons";
import {
  useWebTitle
} from "src/app/hooks/title-hook";
import {
  useLoginEmail
} from "./LoginEmail.hooks";
import {
  Button,
  Info,
  Link,
  Stack,
  Typography
} from "src/app/components";
import {
  HookFormProvider,
  InputTextField
} from "src/app/components/HookForm";

function LoginEmailPage() {

  useWebTitle('Login');

  const state = useLoginEmail();

  return (
    <Stack direction="row" className="h-screen">
      <Stack
        direction="col"
        className="items-center justify-between flex-1 bg-center bg-cover py-14"
        style={{ backgroundImage: "url(/assets/login/img/bg-login.webp)" }}
      >
        <img
          className="h-16"
          src="/assets/login/img/logo-pakuwon.png"
          alt="Plaza Blok M"
        />
        <img
          className="h-48"
          src="/assets/login/img/logo-pg-card.png"
          alt="Plaza Blok M"
        />
        <Stack direction="row" gap={6} className="justify-center">
          <img
            className="h-10"
            src="/assets/login/img/tenant-plaza-blok-m.png"
            alt="Plaza Blok M"
          />
          <img
            className="h-10"
            src="/assets/login/img/tenant-gandaria-city.png"
            alt="Gandaria City"
          />
          <img
            className="h-10"
            src="/assets/login/img/tenant-pakuwon-mall.png"
            alt="Pakuwon Mall"
          />
          <img
            className="h-10"
            src="/assets/login/img/tenant-kasablanka.png"
            alt="Kasablanka"
          />
        </Stack>
      </Stack>
      <div className="flex items-center justify-center flex-1 overflow-y-auto">
        <Stack className="max-w-md" gap={6}>
          <img
            className="w-20"
            src="/assets/login/img/pg-card.png"
            alt="PG Card"
          />
          <Typography variant="h4" className="mb-1">Login to your account</Typography>
          <HookFormProvider form={state.form}>
            {(state.loginEmail.isError) && (
              <Info severity="danger">
                {state.loginEmail.error?.response?.data?.response_schema?.response_message?.en}
              </Info>
            )}
            <InputTextField
              placeholder="Enter your email"
              startIcon={EmailIcon}
              label="Email"
              rule={{ required: true }}
              name="email"
              type="email"
            />
            <InputTextField
              placeholder="Enter your password"
              startIcon={PasswordIcon}
              rule={{ required: true }}
              label="Password"
              type="password"
              name="password"
            />
          </HookFormProvider>
          <div className="p-3 text-right">
            <Link className="text-primary-500" to="/auth/forgot-password">
              Forgot Password?
            </Link>
          </div>
          <Button
            loading={state.loginEmail.isLoading}
            onClick={state.handleSubmit}
            size="lg"
          >
            Login
          </Button>
        </Stack>
      </div>
    </Stack>
  );
}

export default LoginEmailPage;
