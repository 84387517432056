import { QRCode } from 'react-qrcode-logo';
import { Button } from "../../Inputs";
import { Dialog } from "../Dialog/Dialog.page";
import { DialogContent } from "../Dialog/DialogContent.page";
import { ShowQRProps } from "./ShowQR.types";

function ShowQR(props: ShowQRProps) {
  return (
    <Dialog
      closeOnBackdropClicked={true}
      dialogButton={<Button>{props.buttonText}</Button>}
      title={props.title}
      classNames={{ wrapper: "w-fit" }}
    >
      <DialogContent className="bg-white">
        <QRCode
          size={props.size}
          value={props.value}
          logoImage={props.image}
          logoPadding={5}
          logoPaddingStyle='circle'
        />
      </DialogContent>
    </Dialog>
  );
}

ShowQR.defaultProps = {
  buttonText: 'Show QR',
  title: 'QR Code',
  size: 320,
  value: ''
} as ShowQRProps;

export { ShowQR }
