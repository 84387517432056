
export const Theme = {
  container: [
    'relative flex flex-wrap bg-white shadow-md rounded-lg text-sm font-medium text-center text-gray-500 overflow-hidden px-2',
    'dark:text-gray-400 dark:bg-gray-800'
  ],
  underline: [
    'absolute transition-[left] bottom-0 h-[2px] bg-primary'
  ]
};
