
export const Theme = {
  item: [
    'inline-block p-4 border-b-[2px] font-[400]  border-transparent rounded-t-lg cursor-pointer',
    'hover:text-gray-600 hover:border-gray-300',
    'dark:hover:text-gray-300'
  ],
  itemActive: [
    'text-neutral-600 font-semibold',
    'hover:text-neutral-700',
    'dark:text-primary-400',
    'dark:hover:text-primary-300 dark:hover:border-gray-700'
  ]
};
