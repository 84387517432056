
export const Theme = {
  emptyState: [
    'flex flex-1 flex-col min-h-[350px] gap-3 justify-center items-center'
  ],
  description: [
    'text-gray-500',
    'dark:text-gray-400'
  ]
};
