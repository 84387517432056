import { DateObject } from "react-multi-date-picker";
import { GroupBase, Props } from "react-select";

import { OmitSelectProps } from "./Select.types";
import { Select } from "./Select.page";
import { MasterMonth, SelectMonthProps } from "./SelectMonth.types";

const dateObject = new DateObject();
export const masterMonth: MasterMonth[] = dateObject.months.map((_, __) => ({ label: _.name, value: __ + 1 }));

function getValue(value: any) {
  if (['string', 'number'].includes(typeof value)) {
    return masterMonth.find((_) => _.value == value);
  }
  return value;
};

export function SelectMonth<
  IsMulti extends boolean = false,
  Group extends GroupBase<MasterMonth> = GroupBase<MasterMonth>
>({
  value,
  ...props
}: SelectMonthProps & Omit<Props<MasterMonth, IsMulti, Group>, OmitSelectProps | 'options' | 'value'>) {

  return (
    <Select
      placeholder="Select month"
      options={masterMonth as any}
      value={getValue(value)}
      {...props}
    />
  );
}
