
export const Theme = {
  container: [
    'rounded-lg max-h-[160px] max-w-full aspect-video object-contain',
    'bg-black',
    'overflow-hidden'
  ],
  image: [
    'max-h-[160px] max-w-full aspect-video object-contain',
    'dark:border-gray-600'
  ]
};
