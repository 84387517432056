import { twMerge } from "tailwind-merge";
import { Typography } from "../../Display";
import { QueryStateContentProps } from "./QueryStateContent.types";
import { Theme } from "./QueryStateContent.theme";

export function QueryStateContent({
  description,
  className,
  children,
  title,
  ...props
}: QueryStateContentProps) {

  return (
    <div className={twMerge(Theme.emptyState, className)} {...props}>
      {(!!title) && <Typography variant="h4" className="font-bold">{title}</Typography>}
      {(!!description) && <Typography className={twMerge(Theme.description)}>{description}</Typography>}
      {children}
    </div>
  );
}
