import axios, { AxiosResponse } from "axios";
import { BaseResponse, Response } from "src/app/models/api.types";

export const URL_REQUEST_UPDATE_AUTH = '/v1/cms/set-password/notification';
export const URL_VERIFY_TOKEN = '/v1/cms/set-password/verify';
export const URL_UPDATE_PASSWORD = '/v1/cms/set-password';

export function requestUpdateAuth(data?: any): Promise<AxiosResponse<BaseResponse<Response<{}>>>> {
  return axios.post(URL_REQUEST_UPDATE_AUTH, data, {
    headers: {'App-Token': process.env.REACT_APP_API_TOKEN},
  });
}

export function verifyToken(data?: any): Promise<AxiosResponse<BaseResponse<Response<{ is_verify: boolean }>>>> {
  return axios.post(URL_VERIFY_TOKEN, data);
}

export function updatePassword(data?: any): Promise<AxiosResponse<BaseResponse<Response<{}>>>> {
  return axios.put(URL_UPDATE_PASSWORD, data);
}
