import { DropzoneOptions, FileError, FileRejection } from "react-dropzone";
import { humanFileSize } from "src/app/utils/input-utils";

export function getRejectReation(options: DropzoneOptions) {
  return (fileError: FileError) => {
    switch (fileError.code) {
      case 'file-invalid-type':
        const acceptedFormat = Array.isArray(options.accept) ? options.accept.join(' / ') : options.accept;
        return (`File format must be ${acceptedFormat}`);

      case 'file-too-large':
        const fileSize = humanFileSize(options.maxSize!);
        return (`Maximum file size is ${fileSize}`);
    }
    return (fileError.message);
  };
}

export function getErrorRejection(fileRejections: FileRejection[], options: DropzoneOptions) {
  const _rejectReations = fileRejections.reduce((stack, item) => {
    const errors = item.errors.map(getRejectReation(options));
    return ([...stack, ...errors]);
  }, [] as string[]);
  return (_rejectReations);
}
