const DirectoryScreen = {
  DIRECTORY: {
    PATH: '/directory/',
    TITLE: 'Directory',
    TITLE_ALT: 'Directory',
    PERMISSION: 'manage_mall.read',
  },
  MALL: {
    PATH: '/directory/mall/index',
    TITLE: 'Mall',
    TITLE_ALT: 'Mall',
    PERMISSION: 'manage_mall.read',
  },
  STORE_CATEGORY: {
    PATH: '/directory/store-category/index',
    TITLE: 'Store Category',
    TITLE_ALT: 'Store Category',
    PERMISSION: 'manage_store_category.read',
  },
  STORE: {
    PATH: '/directory/store/index',
    TITLE: 'Store',
    TITLE_ALT: 'Store',
    PERMISSION: 'manage_store.read',
  },
  ADD_STORE: {
    PATH: '/directory/store/add',
    TITLE: 'Add Store',
    TITLE_ALT: 'Add Store',
    PERMISSION: 'manage_store.create',
  },
  DETAIL_STORE: {
    PATH: '/directory/store/detail/:id',
    TITLE: 'Detail Store',
    TITLE_ALT: 'Detail Store',
    PERMISSION: 'manage_store.read',
  },
  EDIT_STORE: {
    PATH: '/directory/store/edit/:id',
    TITLE: 'Edit Store',
    TITLE_ALT: 'Edit Store',
    PERMISSION: 'manage_store.update',
  },
}

export default DirectoryScreen;
