import React, { FC, useEffect } from 'react'
import { PageLink, usePageData } from 'src/app/context/PageDataContext'
import { AppName } from 'src/app/utils/title-utils'

type Props = {
  children: string
  description?: string
  breadcrumbs?: Array<PageLink>
}

const PageTitle: FC<Props> = ({ children, description, breadcrumbs }) => {
  const { setPageTitle, setPageDescription, setPageBreadcrumbs } = usePageData()

  useEffect(() => {
    if (children) setPageTitle(children)
    return () => {
      setPageTitle('')
    }
    //eslint-disable-next-line
  }, [children])

  useEffect(() => {
    setPageDescription(description ?? '')
    return () => {
      setPageDescription('')
    }
    //eslint-disable-next-line
  }, [description])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
    //eslint-disable-next-line
  }, [breadcrumbs])


  useEffect(() => {
    if (!description) return
    const appName = AppName()
    document.title = `${description} | ${appName}`
  }, [description])

  return null
}

const PageDescription: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { setPageDescription } = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString())
    }
    return () => {
      setPageDescription('')
    }
  }, [children, setPageDescription])
  return null
}



export { PageDescription, PageTitle }
