
export function hasArray(arr: any): arr is any[] {
  return Array.isArray(arr) && arr.length > 0;
}

export function safeArray<T extends any>(arr?: T | T[]): T[] {
  return hasArray(arr) ? arr : [];
}

export function truncateLabel<T>(
  arr?: T[],
  mapArr: (item: T) => string = (_) => _ as string,
  maxLength: number = 3
): string {

  if (!Array.isArray(arr)) {
    return ('-');
  }

  const mappingArr = arr.map((_: T) => mapArr(_));
  if (arr.length < maxLength + 1) {
    return mappingArr.join(', ');
  }
  const label = mappingArr.slice(0, maxLength).join(', ');
  return (label + '...');
};


export function fillArray  (data?: any[], max?: number) {
  if(!max) max = 10
  if(!data) return []
  if (data.length === max) {
    return data
  }
  
  let result: any[] = []
  if (data?.length < max) {
    result.push(...data)
    for (let index = data.length; index < max; index++) {
      result.push({})
    }
  }


  return result
}

type Option = {
  label: string
  value: string
}

export const convertArrayToObject = (inputArray: Option[]): { [key: string]: Option } => {
  const outputObject: { [key: string]: Option } = {};
  inputArray.forEach(item => {
      outputObject[item.value] = item;
  });
  return outputObject;
}