import { ReactNode, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { ModulePermissionQuery } from '../modules/user_and_permissions/models/Permission.model'
import { useGetProfile } from '../modules/user_and_permissions/providers/User.provider'

export function usePermissions(pageQuery?: ModulePermissionQuery) {
  const history = useHistory()
  const userState = useGetProfile()
  const user = userState.data

  const hasAccess = useCallback(
    (q?: ModulePermissionQuery) => {
      if (user?.role?.status !== "ACTIVE") return false
      const code = q ?? pageQuery
      if (!code) return true
      return Boolean(user.role.permissions?.find((data) => data === (q ?? pageQuery ?? '')))
    },
    [pageQuery, user]
  )

  const canAccessPage = useMemo(() => hasAccess(), [hasAccess])

  const accessRedirect = useCallback(
    (q?: ModulePermissionQuery, path?: string) => {
      if (q) {
        if (canAccessPage && hasAccess(q)) {
          return
        }
        if (path) return history.push(path)
        return history.goBack()
      }
    },
    [canAccessPage, hasAccess, history]
  )

  const setVisibleByAccess = (q: ModulePermissionQuery[], child: ReactNode, fallbackChild?: ReactNode) => {
    const isVisible = q.every(_ => hasAccess(_))
    return (isVisible) ? child : fallbackChild;
  }
  return { hasAccess, canAccessPage, accessRedirect, setVisibleByAccess }
}
