import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useParams } from "react-router-dom";
import { createSelector } from "reselect";

import { BaseResponse, Pagination, QueryModel, Response, ResponseFieldError, ResponseList } from "src/app/models/api.types";
import { useApiParams } from "src/app/hooks";
import { CampaignModel } from "../models/Campaign.model";
import { URL_CAMPAIGN, deleteCampaign, getCampaign, getCampaignDetail, saveCampaign } from "../services/Campaign.service";

// get campaign
const selectCampaign = createSelector(
  (state: AxiosResponse<BaseResponse<Pagination<CampaignModel[]>>>) => state.data,
  (data: BaseResponse<Pagination<CampaignModel[]>>) => data.response_output?.list
);
export function useGetCampaign(param?: QueryModel) {

  const defaultParams = {
    directory_ids: '',
    period: ''
  };
  const [_params] = useApiParams({ defaultValues: defaultParams });
  const params = { ...param, ..._params  };

  return useQuery<
    AxiosResponse<BaseResponse<Pagination<CampaignModel[]>>>,
    AxiosError<BaseResponse<Response<any>>>,
    ResponseList<CampaignModel[]> | undefined
  >({
    keepPreviousData: true,
    queryKey: [URL_CAMPAIGN, params],
    queryFn: (_) => getCampaign(params, _.signal),
    select: selectCampaign,
  });
}

// get campaign detail
const selectCampaignDetail = createSelector(
  (state: AxiosResponse<BaseResponse<Response<CampaignModel>>>) => state.data,
  (data: BaseResponse<Response<CampaignModel>>) => {
    const { directory, created_at, created_by, updated_at, updated_by, ...rest } = data.response_output?.detail ?? {};
    const newDirectory = {
      label: directory?.directory_name,
      value: directory?.id,
      ...directory
    }

    return {
      ...rest,
      directory: newDirectory
    };
  }
);
export function useGetCampaignDetail() {

  const params = useParams<{ id: string }>();

  return useQuery<
    AxiosResponse<BaseResponse<Response<CampaignModel>>>,
    AxiosError<BaseResponse<Response<CampaignModel>>>,
    CampaignModel | undefined
  >({
    keepPreviousData: false,
    queryKey: [`${URL_CAMPAIGN}/${params.id}`],
    queryFn: (_) => getCampaignDetail(params.id, _.signal),
    select: selectCampaignDetail,
    enabled: !!params.id
  });
}

// save campaign
export function useSaveCampaign() {

  return useMutation<
    AxiosResponse<BaseResponse<Response<CampaignModel>>>,
    AxiosError<BaseResponse<ResponseFieldError>>,
    CampaignModel
  >({
    mutationKey: [URL_CAMPAIGN],
    mutationFn: saveCampaign
  });
}

// delete campaign
export function useDeleteCampaign() {

  return useMutation<
    AxiosResponse<BaseResponse<Response<{}>>>,
    AxiosError<BaseResponse<Response<any>>>,
    any
  >({
    mutationKey: [URL_CAMPAIGN],
    mutationFn: deleteCampaign
  });
}
