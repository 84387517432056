const DashboardScreens = {
  DASHBOARD: {
    PATH: '/dashboard',
    TITLE: 'Dashboard',
    TITLE_ALT: 'Dashboard',
  },
  ADMIN_DASHBOARD: {
    PATH: '/dashboard/admin-dashboard/:path',
    TITLE: 'Admin',
    TITLE_ALT: 'Dashboard Admin',
    PERMISSION: 'manage_admin_dashboard.read',
  },
  TENANT_DASHBOARD: {
    PATH: '/dashboard/tenant-dashboard',
    TITLE: 'Tenant',
    TITLE_ALT: 'Dashboard',
    PERMISSION: 'manage_tenant_dashboard.read',
  },
  TENANT_DASHBOARD_SELECTED: {
    PATH: '/dashboard/tenant-dashboard/:merchant_id/:path',
    TITLE: 'Tenant',
    TITLE_ALT: 'Dashboard',
    PERMISSION: 'manage_tenant_dashboard.read',
  },
  TENANT_DASHBOARD_REWARD_DETAIL: {
    PATH: '/dashboard/tenant-dashboard/:merchant_id/dashboard-reward/:id/:path',
    TITLE: 'Tenant',
    TITLE_ALT: 'Dashboard',
    PERMISSION: 'manage_tenant_dashboard.read',
  },
  TENANT_DASHBOARD_PROMO_DETAIL: {
    PATH: '/dashboard/tenant-dashboard/:merchant_id/dashboard-promo/:id/:path',
    TITLE: 'Tenant',
    TITLE_ALT: 'Dashboard',
    PERMISSION: 'manage_tenant_dashboard.read',
  },
}

export default DashboardScreens
