import {
  twMerge
} from "tailwind-merge";
import {
  createPortal
} from "react-dom";
import {
  Ref,
  forwardRef,
  useImperativeHandle,
  useState,
  AnimationEvent,
  useEffect,
  MouseEvent,
  useRef,
  cloneElement,
} from "react";

import {
  Theme
} from "./Menu.theme";
import {
  MenuProps,
  MenuRef
} from "./Menu.types";
import {
  MenuTitle
} from "./MenuTitle.page";
import { CaretIcon } from "../../Icons";

function _Menu(
  {
    withCaret = true,
    closeOnClick,
    classNames,
    menuButton,
    className,
    children,
    onShow,
    onHide,
    title,
    ...props
  }: MenuProps,
  forwardedRef: Ref<MenuRef>
) {

  const [visible, setVisible] = useState<boolean>(false);
  const [dismiss, setDismiss] = useState<boolean>(false);
  const [style, setStyle] = useState({});

  const anchorRef = useRef<HTMLElement>();
  const menuRef = useRef<HTMLDivElement>(null);

  const hide = () => setDismiss(true);
  const show = (event?: MouseEvent<HTMLElement>) => {
    anchorRef.current = event?.currentTarget;
    setMenuLayout();
    setVisible(true);
  }

  useImperativeHandle(
    forwardedRef,
    () => ({ show, hide }),
    []
  );

  const setMenuLayout = () => {
    const leftSpacing = 16;
    const topSpacing = 16;
    const {
      height: menuHeight = 0,
      width: menuWidth = 0
    } = menuRef.current?.getBoundingClientRect() || {};
    const {
      height = 0,
      width = 0,
      x = 0,
      y = 0
    } = anchorRef.current?.getBoundingClientRect() || {};
    const offsetLeft = (width + x) - menuWidth;
    const offsetTop = window.scrollY + topSpacing + height + y;
    const overflowBottom = Math.max(0, topSpacing + (topSpacing + menuHeight + height + y) - window.innerHeight);
    setStyle({
      left: Math.max(leftSpacing, offsetLeft),
      top: Math.max(topSpacing, offsetTop - overflowBottom)
    });
  };

  useEffect(() => {
    const handleBodyClick = () => {
      if (!!menuRef.current && !menuRef.current.classList.contains('menu-hidden')) {
        setDismiss(true);
      }
    };
    document.body.addEventListener('mouseup', handleBodyClick);
    return () => {
      document.body.removeEventListener('mouseup', handleBodyClick);
    }
  }, []);

  useEffect(() => {
    if (!!anchorRef.current) {
      window.addEventListener('resize', setMenuLayout);
      return () => {
        window.removeEventListener('resize', setMenuLayout);
      }
    }
  }, [!anchorRef.current]);

  const handleMouseUp = (event: MouseEvent<HTMLDivElement>) => {
    if (!closeOnClick) {
      event.nativeEvent.stopImmediatePropagation()
      event.stopPropagation();
    }
  };

  const handleAnimEnd = (event: AnimationEvent<HTMLDivElement>) => {
    switch (event.animationName) {
      case 'modal-open':
        onShow?.();
        break;

      case 'modal-close':
        setStyle({ top: "100%", left: "100%" });
        setVisible(false);
        setDismiss(false);
        onHide?.();
        break;
    }
  };

  return (
    <>
      {!!menuButton && cloneElement(menuButton, {
        onClick: (_: any) => show(_),
        ...menuButton.props,
        className: twMerge(menuButton.props?.className, classNames?.button),
        key: menuButton.key,
        ...(withCaret && !!menuButton.props?.variant && {
          classes: { endIcon: twMerge("transition-transform stroke-current", visible && "-rotate-180") },
          endIcon: CaretIcon
        })
      })}
      {createPortal(
        <div
          aria-hidden={!visible}
          onAnimationEnd={handleAnimEnd}
          onMouseUp={handleMouseUp}
          style={style}
          ref={menuRef}
          className={twMerge(
            Theme.menu,
            dismiss && "animate-modal-close",
            visible && !dismiss && "animate-modal-open",
            !visible && "menu-hidden",
            className
          )}
          {...props}
        >
          {(!!title) && <MenuTitle>{title}</MenuTitle>}
          <div>
            {children}
          </div>
        </div>,
        document.body
      )}
    </>
  );
}

export const Menu = forwardRef(_Menu);
