import { twMerge } from "tailwind-merge";

import { Typography } from "../../Display";
import { MenuTitleProps } from "./MenuTitle.types";

export function MenuTitle({
  className,
  children,
  ...props
}: MenuTitleProps) {

  return (
    <div className={twMerge("px-6 py-4 border-b dark:border-gray-600", className)} {...props}>
      <Typography type="medium" variant="h6">{children}</Typography>
    </div>
  );
}
