import { DateObject } from "react-multi-date-picker";

export const rewardTypes = [
  {
    label: 'E-Voucher',
    value: '.DIGITAL'
  },
  {
    label: 'Physical Voucher',
    value: '.FISIK'
  }
];

export const statuses = [
  {
    label: 'Active',
    value: 'ACTIVE'
  },
  {
    label: 'Inactive',
    value: 'INACTIVE',
  },
  {
    label: 'Pending',
    value: 'PENDING'
  }
];

const dateObject = new DateObject();
export const months = dateObject.months.map((_) => ({ label: _.name, value: _.name.toLowerCase() }));
