import axios from "axios";

import {
  BaseResponse,
  Pagination,
  QueryModel,
  Response
} from "src/app/models/api.types";

import {
  StoreImageModel,
  StoreModel
} from "../models/Store.model";

export const URL_STORE = '/v1/cms/merchants';
export const URL_STORE_IMAGE = '/v1/cms/merchants/image';

export async function getStore(params: QueryModel, signal?: AbortSignal) {
  return axios.get<BaseResponse<Pagination<StoreModel[]>>>(URL_STORE, { params, signal });
}

export async function getStoreDetail(id: string, signal?: AbortSignal) {
  return axios.get<BaseResponse<Response<StoreModel>>>(`${URL_STORE}/${id}`, { signal });
}

export function saveStore(params: StoreModel) {
  if (!params.id) {
    return axios.post<BaseResponse<Response<StoreModel>>>(URL_STORE, params);
  }
  return axios.put<BaseResponse<Response<StoreModel>>>(`${URL_STORE}/${params.id}`, params);
}

export async function deleteStore(id: string) {
  return axios.delete<BaseResponse<Response<{}>>>(`${URL_STORE}/${id}`);
}

export function saveStoreImage(params: StoreImageModel) {
  return axios.post<BaseResponse<Response<StoreImageModel>>>(URL_STORE_IMAGE, params)
}
