import axios from "axios";
import { BaseResponse, Pagination, Response } from "src/app/models/api.types";
import { CampaignModel } from "../models/Campaign.model";

export const URL_CAMPAIGN = '/v1/cms/campaigns';

// get campaign
export async function getCampaign(params: any, signal?: AbortSignal) {
  return axios.get<BaseResponse<Pagination<CampaignModel[]>>>(`${URL_CAMPAIGN}`, { params, signal });
}

// get campaign detail
export async function getCampaignDetail(id: string, signal?: AbortSignal) {
  return axios.get<BaseResponse<Response<CampaignModel>>>(`${URL_CAMPAIGN}/${id}`, { signal });
}

// save campaign
export function saveCampaign(params: CampaignModel) {
  if (!params.id) {
    return axios.post<BaseResponse<Response<CampaignModel>>>(URL_CAMPAIGN, params);
  }
  return axios.put<BaseResponse<Response<CampaignModel>>>(`${URL_CAMPAIGN}/${params.id}`, params);
}

// delete campaign
export async function deleteCampaign(id: string) {
  return axios.delete<BaseResponse<Response<{}>>>(`${URL_CAMPAIGN}/${id}`);
}
