import { useUrlSearchParams } from "src/app/hooks";
import { getParamKey } from "./TableCell.utils";

export function useTableCellParams<T extends string>(
  paramKey?: string
): [{ [key in T]?: string }, (props: { [key in T]?: string }) => void] {

  const [searchParams, setSearchParams] = useUrlSearchParams();

  const param = getParamKey(paramKey);

  const params = {
    page: searchParams[param.page],
    sort: searchParams[param.sort],
    order: searchParams[param.order],
    limit: searchParams[param.limit],
  };

  return [
    params,
    setSearchParams
  ];
}
