export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string) {
  if (!pathname || !url) {
    return false
  }

  if (pathname.startsWith(url)) {
    return true
  }

  return false
}
