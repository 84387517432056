import { SVGProps } from "react";

export function BakcIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill="currentColor"
        stroke="currentColor"
        d="M2.377 9.462l.33-.344-.005-.004L8.326 3.21a.653.653 0 01.962 0 .796.796 0 010 1.079L5.366 8.406l-.805.844H17.143c.36 0 .69.312.69.75s-.33.75-.69.75H4.56l.806.845L9.29 15.71s0 0 0 0 0 0 0 0c.282.295.281.784 0 1.079a.653.653 0 01-.96 0l-5.954-6.248a.796.796 0 010-1.079z"
      ></path>
    </svg>
  );
}
