import { SVGProps } from "react";

export function FilterIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill="currentColor"
        stroke="currentColor"
        d="M2.246 3.479h0l.002-.004A.795.795 0 012.968 3h14.065c.303 0 .584.178.72.475.138.3.095.65-.106.904 0 0 0 0 0 0l-5.954 7.483-.109.137V16.429a.57.57 0 01-.303.512.516.516 0 01-.558-.05h0l-2.084-1.608h0l-.002-.002a.575.575 0 01-.22-.46V12l-.109-.137-5.957-7.486s0 0 0 0a.867.867 0 01-.105-.897z"
      ></path>
    </svg>
  );
}
