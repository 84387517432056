import {
  useParams,
} from 'react-router-dom'

import {
  DatePicker,
  Label,
  MenuContent,
  Radio, Select, SelectAsync,
  Stack,
} from 'src/app/components'
import {
  getPeriodValue,
} from './FilterMenuContent.utils'
import {
  useFilterMenuContentHook,
} from './FilterMenuContent.hooks'
import {
  FilterMenuContentProps,
} from './FilterMenuContent.types'
import MenuContentMall from 'src/app/components/Feedback/MenuContent/MenuContent.mall'
import {getRole} from '../../../modules/user_and_permissions/services/Role.service'

function FilterMenuContent(props: FilterMenuContentProps) {

  const params = useParams<{path: string}>()
  const state = useFilterMenuContentHook()
  const statusMaster = state.master.getStatusMaster(props)

  const renderRewardItem = (item: typeof state.master.rewardTypes[0]) => {
    return (
      <Radio
        label={item.label}
        {...state.filterContext.registerRadio({name: 'reward_types', value: item.value})}
        key={item.value}
      />
    )
  }

  const renderStatusItem = (item: typeof state.master.statuses[0]) => {
    return (
      <Radio
        {...state.filterContext.registerRadio({name: 'status', value: item.value})}
        label={item.label}
        key={item.value}
      />
    )
  }

  return (
    <MenuContent>
      {(props.withRewardType) && (
        <div>
          <Label>Reward Type</Label>
          <Stack direction="row">
            {state.master.rewardTypes.map(renderRewardItem)}
          </Stack>
        </div>
      )}
      {(params.path === 'birthday-gift') ? (
        <DatePicker
          placeholder="Select date"
          onChange={state.action.handleChangeMonth}
          format="MMMM YYYY"
          value={state.filterContext.draft?.months}
          label="Time"
          onlyMonthPicker
          range={false}
        />
      ) : (
        props.withPeriod && (
          <DatePicker
            placeholder="Select date"
            onChange={state.action.handleChangePeriod}
            format="DD/MM/YYYY"
            value={getPeriodValue(state.filterContext.draft?.period)}
            range={true}
            label="Period"
          />
        )
      )}
      {(props.withMall) && <MenuContentMall filterContext={state.filterContext} />}
      {(props.withStatus) && (
        <div>
          <Label>Status</Label>
          <Stack direction="row">
            {statusMaster.map(renderStatusItem)}
          </Stack>
        </div>
      )}
      {props.withRole && (
        <>
          <div>
            <Label>Role</Label>
            <SelectAsync
              placeholder="Select role"
              fetchApi={getRole}
              isMulti={true}
              name="role_ids"
              payload={{statuses: ['ACTIVE']}}
              selectOption={(item) => ({
                label: item?.role_name!,
                value: item?.id,
              })}
              value={state.action.getSelectRoleValue()}
              onChangeValue={state.action.handleSelectRole}
            />
          </div>
        </>
      )}
      {props.withMenuAction && (
        <>
          <div>
            <Label>Menu</Label>
            <Select
              placeholder="Select Menu"
              name="menu_codes_ids"
              options={state.state.menuOptions}
              disabled={state.state.menuOptions.length === 0}
              value={state.action.getSelectMenuValue()}
              onChangeValue={state.action.handleSelectMenu}
            />
          </div>
          <div>
            <Label>Action</Label>
            <Select
              placeholder="Select Actions"
              name="action_codes_ids"
              options={state.state.actionOptions}
              disabled={state.state.actionOptions.length === 0}
              value={state.action.getSelectActionValue()}
              onChangeValue={state.action.handleSelectAction}
            />
          </div>
        </>
      )}
    </MenuContent>
  )
}

FilterMenuContent.defaultProps = {
  withStatusInactive: true,
  withStatusPending: true,
  withStatusActive: true,
  withStatus: true,
  withMall: true,
} as FilterMenuContentProps

export {FilterMenuContent}
