import { useForm } from "react-hook-form";
import { useUrlSearchParams } from "src/app/hooks";
import { useUpdatePassword, useVerifyToken } from "../../providers/SetPassword.provider";
import { useEffect } from "react";

const defaultForm = {
  confirm_password: '',
  password: ''
}

export function useCreateNewPassword() {

  const updatePassword = useUpdatePassword<'password' | 'token' | 'type'>();
  const [searchParams] = useUrlSearchParams<'token' | 'type'>();
  const verifyToken = useVerifyToken<'token'>();
  const form = useForm({ defaultValues: defaultForm });


  const tokenVerification = async () => {
    try {
      await verifyToken.mutateAsync({ token: searchParams.token! });
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    tokenVerification()
  }, [])


  const handleSubmit = form.handleSubmit(async (formData) => {
    try {
      updatePassword.mutate({
        password: formData.password,
        token: searchParams.token!,
        type: searchParams.type!
      });

    } catch (error) {
      console.log("🚀 ~ file: CreateNewPassword.hooks.ts:30 ~ handleSubmit ~ error:", error);
    }
  });

  return ({
    updatePassword,
    handleSubmit,
    verifyToken,
    form
  });
}
