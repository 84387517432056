import {
  ChangeEvent,
  Children,
  createElement,
  isValidElement,
  useMemo,
  useRef
} from "react";

import {
  InputGroup
} from "../../Inputs/InputGroup/InputGroup.page";
import {
  InputFormGroupProps
} from "./InputFormGroup.types";

export function InputFormGroup({
  name: parentName,
  rule: parentRule,
  helperText,
  children,
  form,
  ...props
}: InputFormGroupProps) {

  const childNames = useRef<string[]>([]);

  const error = useMemo(() => {
    const inputName = (!parentName) ? childNames.current : [parentName, ...childNames.current];
    const errorKey = inputName.find((_) => !!form.formState.errors[_]);
    return (!errorKey) ? null : form.formState.errors[errorKey];
  }, [Object.keys(form.formState.errors).length]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    form.setValue(event.target.name, event.target.value);
    form.clearErrors(event.target.name);
  };

  const setValueAs = (value?: string) => {
    if (value === 'true') {
      return (true);
    }
    if (value === 'false') {
      return (false);
    }
    return (value);
  };

  return (
    <InputGroup
      helperText={!error ? helperText : error?.message?.toString()}
      required={!!parentRule?.required}
      error={!!error}
      {...props}
    >
      {Children.map(children, (child, index) => {
        if (isValidElement(child)) {
          const {
            name = parentName,
            rule = parentRule
          } = child.props;
          childNames.current[index] = name;
          if (!!name) {
            return createElement(child.type, {
              ...child.props,
              ...form.register(name, { ...rule, setValueAs }),
              onChange: handleInputChange,
              value: form.watch(name),
              key: child.key
            });
          }
        }
        return (child);
      })}
    </InputGroup>
  );
}
