import React from 'react'
import { useGetProfile } from '../user_and_permissions/providers/User.provider'

type WelcomePageProps = {}

const WelcomePage = (props: WelcomePageProps) => {
    const profile = useGetProfile()
    return (
        <div className='rounded-lg card' style={{ minHeight: 'calc(100% - 30px)' }}>
            <div className='flex flex-col items-center justify-center card-body'>
                <h1 className='text-3xl font-light'>Hi, <strong className='font-bold'>{profile.data?.name}</strong> </h1>
                <p className='text-base text-neutral-500'>Welcome to Pakuwon App CMS</p>
            </div>
        </div>
    )
}

export default WelcomePage