import clsx from 'clsx'
import React, {Children, useState} from 'react'
import {useLocation} from 'react-router'
import {useMasterLayout} from 'src/app/context/MasterContext'
import {checkIsActive} from 'src/app/utils/router-helpers-utils'
import {KTSVG as Svg} from '../../Libs/Icon/KTSVG'
import BulletItem from './BulletItem'
import { twMerge } from 'tailwind-merge'

type Props = {
  children?: any
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  activePath?: string
}

const AsideMenuItemWithSub: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {minimize} = useMasterLayout()
  const splitPathname = '/' + pathname.split('/')[1]
  const [open, setOpen] = useState<string>(splitPathname)

  const handleOpen = (value: string) => {
    setOpen(open === value ? '' : value)
  }

  const allChildren = Children.toArray(children)

  let countArray = allChildren.length
  let newMaxHeight = `max-h-[${3 * countArray + 3}rem]`

  allChildren.forEach((child: any) => {
    const {props} = child

    if (props.menu?.children !== undefined) {
      const menuLength = props.menu.children.length
      countArray += menuLength
    }
  })

  return (
    <div
      className={clsx('p-0 block overflow-hidden text-fs-9', {
        'overflow-visible': to,
      })}
      onClick={() => handleOpen(to)}
    >
      <span
        className={clsx(
          'p-3 mx-3 cursor-pointer flex items-center',
          'hover:text-primary-light-2',
          {'bg-primary-600 text-primary-light-2 dark:bg-inverse-primary-light-3 rounded-lg group-hover:bg-transparent group-hover:rounded-none': isActive && minimize},
          {
            'text-primary-light-2': open,
            'text-neutral-50 ': !open,
          }
        )}
      >
        {hasBullet && <BulletItem isActive={isActive} />}
        {icon && (
          <span className='flex items-center w-8 mr-2 shrink-0'>
            <Svg path={icon} className='w-6 h-6' />
          </span>
        )}
        {/* still bootstrap style / change it !  */}
        {fontIcon && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span
          className={clsx('truncate flex items-center flex-1 transition-all duration-300', {
            'opacity-0 group-hover:opacity-100': minimize,
          })}
        >
          {title}
        </span>
        <span
          className={clsx(
            'flex items-stretch relative overflow-hidden ml-2 w-[0.8rem] h-[0.8rem] transition ease-in',
            'after:block after:content-[""] after:w-full after:bg-[url("/public/media/icons/IconChevronRight.svg")] after:bg-center after:bg-no-repeat',
            {'rotate-90': open === to}
          )}
        ></span>
      </span>
      <div
        onClick={(e) => e.stopPropagation()}
        className={twMerge([
          "pl-6 rounded-lg transition-all overflow-hidden duration-300 ease-in",
          open === to && newMaxHeight,
          open !== to && 'max-h-0 ',
          minimize && 'hidden group-hover:block',
        ])}
      >
        {children}
      </div>
    </div>
  )
}

export {AsideMenuItemWithSub}
