
import dayjs from "dayjs";

import CampaignScreens from "../../CampaignScreens";

import {
  useLocation
} from "react-router-dom";
import {
  useRef
} from "react";

import {
  PageTitle
} from "src/app/components/Layout/header/HeaderTitle";
import {
  usePermissions
} from "src/app/hooks/permissions-hook";
import {
  Alert,
  BackButton,
  Button,
  ChipStatus,
  Content,
  ContentAction,
  ContentHeader,
  DialogRef,
  LabelItem,
  QueryState,
} from "src/app/components";
import {
  hasArray
} from "src/app/utils/array-utils";
import {
  useCampaignDetailHooks
} from "./CampaignDetail.hooks";

function CampaignDetailPage() {

  const alertForceStopRef = useRef<DialogRef>(null);
  const alertDeleteRef = useRef<DialogRef>(null);
  const permission = usePermissions();
  const location = useLocation();
  const state = useCampaignDetailHooks();

  const isHistory = location.pathname.includes('/history');
  const { data } = state.campaign;

  const breadcrumbs = [
    {
      isActive: false,
      path: isHistory ? CampaignScreens.LIST_CAMPAIGN.PATH.replace(':path', 'history') : CampaignScreens.LIST_CAMPAIGN.PATH.replace(':path', 'index'),
      title: isHistory ? CampaignScreens.LIST_CAMPAIGN_HISTORY.TITLE : CampaignScreens.LIST_CAMPAIGN.TITLE,
    },
    { isActive: false, path: '', title: '', isSeparator: true },
  ];

  return (
    <>
      <PageTitle description="Detail" breadcrumbs={breadcrumbs}>
        {CampaignScreens.CAMPAIGN.TITLE}
      </PageTitle>
      <BackButton />
      <Content>
        <ContentHeader title="Detail Campaign" />
        <QueryState query={state.campaign}>
          <>
            <div className="px-6">
              <LabelItem
                label="Campaign Type"
                value={data?.campaign_type && data?.campaign_type?.charAt(0).toUpperCase()+data?.campaign_type?.slice(1).toLowerCase()+" Point"}
              />
              <LabelItem
                label="Mall"
                value={data?.directory?.directory_name}
              />
              <LabelItem
                renderValue={(_) => `${dayjs(_).format("DD/MM/YYYY")} - ${dayjs(data?.end_date).format("DD/MM/YYYY")}`}
                label="Period"
                value={data?.start_date}
              />
              <LabelItem
                renderValue={(_) => `${data?.time_type?.split('_').join(' ')} (${data?.start_time} - ${data?.end_time})`}
                label="Time"
                value={data?.time_type}
              />
              <LabelItem
                renderValue={(_) => _.map((__) => __?.category_name)}
                validate={hasArray}
                label="Category"
                value={data?.merchant_categories}
              />
              <LabelItem
                renderValue={(_) => _.map((__) => __?.merchant_name)}
                validate={hasArray}
                label="Store"
                value={data?.merchants}
              />
              <LabelItem
                renderValue={(_) => _.map((__) => __?.payment_category_name)}
                validate={hasArray}
                label="Payment Category"
                value={data?.payment_categories}
              />
              <LabelItem
                renderValue={(_) => _.map((__) => __?.method_name)}
                validate={hasArray}
                label="Payment Method"
                value={data?.payment_methods}
              />
              {(!isHistory) && (
                <LabelItem label="Status">
                  <div>
                    <ChipStatus state={data?.status} />
                  </div>
                </LabelItem>
              )}
            </div>
            {(!isHistory) && (
              <ContentAction>
                {(data?.status?.toLowerCase() === "active") ?
                  permission.setVisibleByAccess(
                    ["campaign.update"],
                    <Button
                      onClick={() => alertForceStopRef.current?.show()}
                      variant="outlined"
                      color="danger"
                    >
                      Force Stop
                    </Button>,
                    <Button className="hidden" />
                  ) :
                  permission.setVisibleByAccess(
                    ["campaign.delete"],
                    <Button
                      onClick={() => alertDeleteRef.current?.show()}
                      variant="outlined"
                      color="danger"
                    >
                      Delete Campaign
                    </Button>,
                    <Button className="hidden" />
                  )
                }
                {permission.setVisibleByAccess(
                  ["campaign.update"],
                  <Button to={CampaignScreens.EDIT_CAMPAIGN.PATH.replace(":id", data?.id!)}>
                    Edit Campaign
                  </Button>,
                  <Button className="hidden" />
                )}
              </ContentAction>
            )}
          </>
        </QueryState>
      </Content>

      <Alert
        onSuccessGoBack={true}
        submitText="Yes"
        mutation={state.saveCampaign}
        title="Force Stop Campaign"
        ref={alertForceStopRef}
        payload={{
          end_date: dayjs().format("YYYY-MM-DD"),
          status: "INACTIVE",
          id: data?.id
        }}
      >
        Are you sure you want to force stop this campaign?
      </Alert>

      <Alert
        onSuccessGoBack={true}
        mutation={state.deleteCampaign}
        submitText="Delete"
        payload={data?.id}
        title="Delete Campaign"
        ref={alertDeleteRef}
      >
        Are you sure you want to delete this campaign?
      </Alert>
    </>
  );
}

export default CampaignDetailPage;