import { DateObject } from "react-multi-date-picker";
import { PropsValue } from "react-select";
import { toCapitalize } from "src/app/utils/string-utils";


export function convertPeriod(value: DateObject | DateObject[] | null): any {
  if (Array.isArray(value)) {
    return value.map(convertPeriod).join('.');
  }
  if (!!value) {
    return value.format('YYYY-MM-DD');
  }
  return undefined;
}

export function getPeriodValue(value?: string) {
  if (!!value) {
    return value.split('.').map((_) => new DateObject(_));
  }
  return undefined;
}

export function getMonthsValue(value?: string): PropsValue<any> {
  if (!!value) {
    return value.split('.').map((_) => ({ label: toCapitalize(_), value: _.toLowerCase() }));
  }
  return [];
}
