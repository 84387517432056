import { SVGProps } from "react";

export function AddRoundedIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10 6.94v6.105M13.056 9.992H6.944"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M13.905 1.667h-7.81c-2.722 0-4.428 1.926-4.428 4.654v7.358c0 2.728 1.698 4.654 4.428 4.654h7.81c2.73 0 4.428-1.926 4.428-4.654V6.321c0-2.728-1.698-4.654-4.428-4.654z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
