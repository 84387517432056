import CampaignScreens from "../../CampaignScreens";

import { PageTabs } from "src/app/components";
import { campaignTabs } from "./CampaignTabs.template";

function CampaignTabsPage() {

  return (
    <PageTabs
      baseRoute={CampaignScreens.LIST_CAMPAIGN.PATH}
      title={CampaignScreens.CAMPAIGN.TITLE}
      data={campaignTabs}
    />
  );
}

export default CampaignTabsPage;
