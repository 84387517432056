import axios from "axios";
import { BaseResponse, Pagination, QueryModel, Response } from "src/app/models/api.types";
import { UserModel } from "../models/User.model";

export const URL_GET_PROFILE = '/v1/cms/users/profile';
export const URL_USER = '/v1/cms/users';

export function getProfile() {
  return axios.get<BaseResponse<Response<UserModel>>>(URL_GET_PROFILE);
}

export const getUser = async (params?: QueryModel) => {
  return axios.get<BaseResponse<Pagination<UserModel[]>>>(URL_USER, {
    params
  })
}

export const getUserDetail = (id: string) => {
  return axios.get<BaseResponse<Response<UserModel>>>(`${URL_USER}/${id}`)
}

export const saveUser = (params: UserModel) => {
  const role_id = params.role?.id
  const name = params?.name
  const email = params?.email
  const user_type = params?.user_type
  const status = params?.status
  const pakuwonDirectoryIds = params?.directories?.map((_) => _?.id)
  const tenantDirectoryIds = params.id ? [params?.directories?.[0]?.id] : [params?.directory?.id]
  const directory_ids = params?.user_type ==="pakuwon" ? pakuwonDirectoryIds  :  tenantDirectoryIds
  const merchant_ids = [params?.merchant?.id]

  const addPayload = {
    name,
      email,
      user_type,
      directory_ids,
      merchant_ids,
      role_id,
  }
  
  const editPayload = {
    name,
    email,
    role_id,
    status,
    directory_ids
  }

  if (!params.id) {
    return axios.post<BaseResponse<Response<UserModel>>>(URL_USER, addPayload)
  }

  return axios.put<BaseResponse<Response<UserModel>>>(`${URL_USER}/${params.id}`, editPayload)
}

export const deleteUser = (id: string) => {
  return axios.delete(`${URL_USER}/${id}`)
}