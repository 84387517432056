import React, { useEffect } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { PageTitle } from '../components/Layout/header/HeaderTitle'
import { usePermissions } from '../hooks/permissions-hook'
import { ScreenProp } from '../models/screen-prop'
import { getTitle } from '../utils/title-utils'
import { ModulePermissionQuery } from '../modules/user_and_permissions/models/Permission.model'

interface Props extends RouteProps {
  screen: ScreenProp
  children?: React.ReactNode
}

const defaultScreen = {
  PATH: '',
  TITLE: '',
  breadcrumbs: () => [],
}

const ProtectedRoute: React.FC<Props> = ({
  component: Component,
  children,
  path,
  screen,
  ...rest
}) => {
  const { hasAccess } = usePermissions(screen.PERMISSION as ModulePermissionQuery)

  return (
    <Route
      {...rest}
      render={(props) => {
        if (hasAccess()) {
          return Component ? <Component {...props} /> : children
        }
        return <ProtectedPage {...screen} />
      }}
    />
  )
}

//eslint-disable-next-line
const ProtectedPage: React.FC<ScreenProp> = ({
  TITLE,
  TITLE_ALT,
  HEADER_NAME,
  breadcrumbs = defaultScreen.breadcrumbs,
}) => {

  useEffect(() => {
    document.title = getTitle(TITLE_ALT)
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <PageTitle description={TITLE}>{HEADER_NAME ?? TITLE_ALT}</PageTitle>
      <div className='rounded-lg card' style={{ minHeight: 'calc(100% - 30px)' }}>
        
        <div className='flex flex-col items-center justify-center card-body'>
          <div>Saat ini Anda tidak dapat mengakses fitur ini.</div>
          <div>Mohon hubungi pihak Admin jika Anda ingin tetap mengakses fitur ini.</div>
        </div>
      </div>
    </>
  )
}

export default ProtectedRoute
