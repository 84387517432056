import {
  useHistory
} from "react-router-dom";
import {
  twMerge
} from "tailwind-merge";
import {
  Children,
  createElement,
  isValidElement
} from "react";

import {
  Stack
} from "../../Layouts";
import {
  ContentActionProps
} from "./ContentAction.types";
import {
  Theme
} from "./ContentAction.theme";

export function ContentAction({
  className,
  children,
  hideCancelButton,
  hideSubmitButton,
  mutation,
  loading,
  action,
  form,
  ...props
}: ContentActionProps) {

  const history = useHistory();

  const [cancelEl, submitEl] = Children.toArray(children);

  const isLoading = mutation?.isLoading || loading;

  return (
    <Stack
      className={twMerge(Theme.container, className)}
      direction="row"
      justify="end"
      gap={4}
      {...props}
    >
      {isValidElement(cancelEl) && !hideCancelButton && createElement(cancelEl.type, {
        disabled: isLoading,
        children: "Cancel",
        onClick: () => history.goBack(),
        color: "primary",
        ...cancelEl.props,
        className: twMerge("min-w-[128px]", cancelEl.props?.className),
        variant: "outlined",
      })}
      {isValidElement(submitEl) && !hideSubmitButton && createElement(submitEl.type, {
        children: "Submit",
        onClick: form?.handleSubmit((_) => mutation?.mutate(_)),
        loading: isLoading,
        color: "primary",
        ...submitEl.props,
        className: twMerge("min-w-[128px]", submitEl.props?.className),
        variant: "contained",
      })}
    </Stack>
  );
}
