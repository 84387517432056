import {ChangeEventHandler} from 'react'
import {Checkbox} from '../Checkbox/Checkbox.page'
import {SelectAllCheckboxProps} from './SelectAllCheckbox.type'
const SelectAllCheckbox = (props: SelectAllCheckboxProps) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.checked) {
      props.onChecked?.(props.options ?? [])
    } else {
      props.onUnChecked?.()
    }
  }

  return (
    <div className='grid items-start grid-cols-3'>
      <div></div>
      <div className='relative col-span-2'>
        <Checkbox
          checked={props.checked}
          onChange={handleChange}
          label={props.label}
          disabled={props.disabled}
        />
      </div>
    </div>
  )
}

export default SelectAllCheckbox
