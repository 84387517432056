import {
  useHistory
} from "react-router-dom";
import {
  useEffect,
  useRef
} from "react";

import {
  EmailIcon
} from "src/app/components/Icons";
import {
  Alert,
  BackButton,
  Button,
  DialogRef,
  Stack,
  Typography
} from "src/app/components";
import {
  useForgotPassword
} from "./ForgotPassword.hooks";
import {
  emailRegExp
} from "src/app/utils/input-utils";
import {
  HookFormProvider,
  InputTextField
} from "src/app/components/HookForm";

function ForgotPasswordPage() {

  const alertEmailSentRef = useRef<DialogRef>(null);
  const history = useHistory();
  const state = useForgotPassword();

  useEffect(() => {
    if (state.requestChangePassword.isSuccess) {
      alertEmailSentRef.current?.show();
    }
  }, [state.requestChangePassword.isSuccess]);

  return (
    <div
      className="flex justify-center items-center h-[100vh] !bg-no-repeat !bg-[length:320px_auto]"
      style={{ background: "url(/assets/password/img/ellipse-tl.png) top left, url(/assets/password/img/ellipse-br.png) bottom right" }}
    >
      <Stack gap={8} className="max-w-md">
        <img
          className="w-20 mx-auto mb-8"
          src="/assets/login/img/pg-card.png"
          alt="PG Card"
        />
        <Stack gap={2} className="text-center">
          <Typography variant="h4">Forgot Password</Typography>
          <Typography>No worries, we’ll send you reset instructions.</Typography>
        </Stack>
        <HookFormProvider form={state.form}>
          <InputTextField
            placeholder="Enter email address"
            startIcon={EmailIcon}
            label="Email"
            name="email"
            rule={{
              required: "Field cannot be empty",
              validate: {
                matchPattern: (_) => emailRegExp.test(_) || "The email format you’re using is incorrect."
              }
            }}
          />
        </HookFormProvider>
        <Button
          loading={state.requestChangePassword.isLoading}
          onClick={state.handleSubmit}
          size="lg"
        >
          Reset Password
        </Button>
        <BackButton>Back to Login</BackButton>
      </Stack>

      <Alert
        cancelText="Back to Login"
        submitText={null}
        onHide={() => history.goBack()}
        title="Email Sent!"
        ref={alertEmailSentRef}
      >
        <Typography>
          We’ve sent a link to reset your password to <strong>{state.form.getValues('email')}</strong>.<br />
          Please follow the instruction in the email to continue
        </Typography>
      </Alert>
    </div>
  );
}

export default ForgotPasswordPage;
