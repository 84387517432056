const PromoScreens = {
  PROMO: {
    PATH: '/promo',
    TITLE: 'Promo',
    TITLE_ALT: 'Promo',
    PERMISSION: '',
  },
  LIST_PROMO: {
    PATH: '/promo/list/:path',
    TITLE: 'List Promo',
    TITLE_ALT: 'Promo',
    PERMISSION: '',
  },
  ADD_PROMO: {
    PATH: '/promo/add',
    TITLE: 'Add Promo',
    TITLE_ALT: 'Add Promo',
    PERMISSION: 'manage_promo.create',
  },
  DETAIL_PROMO: {
    PATH: '/promo/detail/:id',
    TITLE: 'Promo',
    TITLE_ALT: 'Detail Promo',
    PERMISSION: 'manage_promo.read',
  },
  EDIT_PROMO: {
    PATH: '/promo/edit/:id',
    TITLE: 'Promo',
    TITLE_ALT: 'Edit Promo',
    PERMISSION: 'manage_promo.update',
  },
  CHOOSE_FEATURED_PROMO: {
    PATH: '/promo/list/:path/choose-promo',
    TITLE: 'Choose Promo',
    TITLE_ALT: 'Choose Promo',
    PERMISSION: 'manage_featured_promo.create',
  },
  DETAIL_FEATURED_PROMO: {
    PATH: '/promo/detail/featured/:id',
    TITLE: 'Detail Featured Promo',
    TITLE_ALT: 'Detail Featured Promo',
    PERMISSION: 'manage_featured_promo.read',
  },
}

export default PromoScreens;
