import axios from "axios";

import {
  BaseResponse,
  Pagination,
  QueryModel,
  Response
} from "src/app/models/api.types";

import {
  StoreCategoryModel
} from "../models/StoreCategory.model";

export const URL_STORE_CATEGORY = '/v1/cms/merchant-categories';

export function getStoreCategory(params: QueryModel, signal?: AbortSignal) {
  return axios.get<BaseResponse<Pagination<StoreCategoryModel[]>>>(URL_STORE_CATEGORY, {
    headers: { 'App-Token': process.env.REACT_APP_API_TOKEN },
    params,
    signal
  });
}

export function getStoreCategoryDetail(id: string, signal?: AbortSignal) {
  return axios.get<BaseResponse<Response<StoreCategoryModel>>>(`${URL_STORE_CATEGORY}/${id}`, { signal });
}

export function saveStoreCategory(params: StoreCategoryModel) {
  if (!params.id) {
    return axios.post<BaseResponse<Response<StoreCategoryModel>>>(URL_STORE_CATEGORY, params);
  }
  return axios.put<BaseResponse<Response<StoreCategoryModel>>>(`${URL_STORE_CATEGORY}/${params.id}`, params);
}

export async function deleteStoreCategory(id: string) {
  return axios.delete<BaseResponse<Response<{}>>>(`${URL_STORE_CATEGORY}/${id}`);
}
