import { twMerge } from "tailwind-merge";

import {
  GridProps
} from "./Grid.types";
import {
  Theme
} from "./Grid.theme";

function Grid({
  className,
  direction,
  gap,
  xl,
  lg,
  md,
  sm,
  ...props
}: GridProps) {

  return (
    <div
      {...props}
      className={twMerge(
        // Theme.grid,
        // `xl:grid-${direction}-${sm}`,
        // `lg:grid-${direction}-${sm}`,
        // `md:grid-${direction}-${sm}`,
        // `sm:grid-${direction}-${sm}`,
        `md:grid-cols-${sm}`,
        `sm:grid-cols-${sm}`,
        `gap-${gap}`,
        // className
      )}
    />
  );
}

Grid.defaultProps = {
  direction: 'cols',
  gap: 4,
  // xl: 1,
  // lg: 1,
  // md: 1,
  sm: 1
} as GridProps;

export { Grid };
