import dayjs from "dayjs";

import CampaignScreens from "../../CampaignScreens";

import {
  ActionButton,
  Content,
  ContentHeader,
  FilterMenu,
  InputSearch,
  Paging,
  QueryState,
  QueryStateContent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "src/app/components";
import {
  useCampaignHistoryHooks
} from "./CampaignHistory.hooks";
import {
  hasArray
} from "src/app/utils/array-utils";
import {
  CampaignModel
} from "../../models/Campaign.model";
import {
  FilterMenuContent
} from "src/app/components/Feedback/FilterMenu/FilterMenuContent.page";

export function CampaignHistory() {

  const state = useCampaignHistoryHooks();

  const renderItem = (item: CampaignModel, index: number) => {
    return (
      <TableRow key={item.id}>
        <TableCell {...{ index }} />
        <TableCell
          renderValue={(_) => dayjs(_).format("DD/MM/YYYY")}
          className="w-[145px]"
          value={item?.start_date}
        />
        <TableCell
          renderValue={(_) => dayjs(_).format("DD/MM/YYYY")}
          className="w-[145px]"
          value={item?.end_date}
        />
        <TableCell
          className="whitespace-nowrap"
          value={item?.directory?.directory_name}
        />
        <TableCell
          renderValue={(_) => _.map((__) => __?.category_name)}
          validate={hasArray}
          value={item.merchant_categories}
        />
        <TableCell
          renderValue={(_) => _.map((__) => __?.merchant_name)}
          validate={hasArray}
          value={item.merchants}
        />
        <TableCell
          renderValue={(_) => _.map((__) => __?.method_name)}
          validate={hasArray}
          value={item?.payment_methods}
        />
        <TableCell action={true}>
          <ActionButton
            className="mx-auto"
            variant="detail"
            to={CampaignScreens.DETAIL_CAMPAIGN_HISTORY.PATH.replace(":id", item.id!)}
          />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Content>
      <ContentHeader title={CampaignScreens.LIST_CAMPAIGN_HISTORY.TITLE} />

      <Stack
        direction="row"
        justify="between"
        items="center"
        className="px-6 py-4"
      >
        <InputSearch />
        <FilterMenu>
          <FilterMenuContent withStatus={false} />
        </FilterMenu>
      </Stack>

      <QueryState query={state.campaign}>
        <div className="px-8">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell index />
                <TableCell order="start_date" className="w-[145px]">Start Date</TableCell>
                <TableCell order="end_date" className="w-[145px]">End Date</TableCell>
                <TableCell>Mall</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Store</TableCell>
                <TableCell>Payment Method</TableCell>
                <TableCell action={true} className="w-[100px]" />
              </TableRow>
            </TableHead>
            <TableBody>
              {state.campaign.data?.content?.map(renderItem)}
            </TableBody>
          </Table>
          <Paging query={state.campaign} />
        </div>
        <QueryStateContent description="" title="List History Campaign is Empty" />
      </QueryState>
    </Content>
  );
}
