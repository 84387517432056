import { SVGProps } from "react";

export function FetchingIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M12 1.2a10.8 10.8 0 11-8.247 17.774l1.65-1.395A8.64 8.64 0 1012 3.36V1.2z"
      ></path>
    </svg>
  );
}
