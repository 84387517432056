export function getTotalExistingKeys(obj: Record<string, any>, targetKeys: string[]) {
  let count = 0;

  for (const targetKey of targetKeys) {
    if (obj.hasOwnProperty(targetKey)) {
      count++;
    }
  }

  return count;
}

export function isObject(obj: any): obj is object {
  return !Array.isArray(obj) && obj === Object(obj);
}

export function hasObject(obj: any): obj is object {
  return isObject(obj) && Object.keys(obj).length > 0;
}

export function hasObjectValues(obj: any): obj is object {
  return isObject(obj) && Object.values(obj).filter((_) => !!_).length > 0;
}


export function createQueryString (obj: Record<string, any>): string {
  return Object.entries(obj).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&')
}

export function filterKeys(input: Record<string, any>, filterParam : string) {
  return Object.keys(input).reduce((acc: any, key) => {
    if (key.startsWith(filterParam)) {
      acc[key] = undefined;
    }
    return acc;
  }, {});
}