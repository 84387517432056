import {
  useMutation,
  useQuery,
  useQueryClient
} from "@tanstack/react-query";
import {
  createSelector
} from "reselect";
import {
  AxiosError,
  AxiosResponse
} from "axios";

import {
  useApiParams,
} from "src/app/hooks";
import {
  BaseResponse,
  Pagination,
  QueryModel,
  Response,
  ResponseFieldError,
  ResponseList
} from "src/app/models/api.types";
import {
  StoreCategoryModel
} from "../models/StoreCategory.model";
import {
  URL_STORE_CATEGORY,
  deleteStoreCategory,
  getStoreCategory,
  getStoreCategoryDetail,
  saveStoreCategory
} from "../services/StoreCategory.service";

const selectStoreCategory = createSelector(
  (state: AxiosResponse<BaseResponse<Pagination<StoreCategoryModel[]>>>) => state.data,
  (data: BaseResponse<Pagination<StoreCategoryModel[]>>) => data.response_output?.list
);
export function useGetStoreCategory() {

  const [params] = useApiParams();

  return useQuery<
    AxiosResponse<BaseResponse<Pagination<StoreCategoryModel[]>>>,
    AxiosError<BaseResponse<Response<any>>>,
    ResponseList<StoreCategoryModel[]> | undefined
  >({
    keepPreviousData: true,
    queryKey: [URL_STORE_CATEGORY, params],
    queryFn: (_) => getStoreCategory(params, _.signal),
    select: selectStoreCategory
  });
}

const selectStoreCategoryDetail = createSelector(
  (state: AxiosResponse<BaseResponse<Response<StoreCategoryModel>>>) => state.data,
  (data: BaseResponse<Response<StoreCategoryModel>>) => data.response_output?.detail
);
export function useGetStoreCategoryDetail(id?: string) {

  return useQuery<
    AxiosResponse<BaseResponse<Response<StoreCategoryModel>>>,
    AxiosError<BaseResponse<Response<any>>>,
    StoreCategoryModel | undefined
  >({
    queryKey: [`${URL_STORE_CATEGORY}/${id}`],
    queryFn: (_) => getStoreCategoryDetail(id!, _.signal),
    select: selectStoreCategoryDetail,
    enabled: !!id
  });
}

export function useSaveStoreCategory() {

  const refetchStoreCategory = useRefetchStoreCategory();

  return useMutation<
    AxiosResponse<BaseResponse<Response<StoreCategoryModel>>>,
    AxiosError<BaseResponse<ResponseFieldError>>,
    StoreCategoryModel
  >({
    mutationKey: [URL_STORE_CATEGORY],
    mutationFn: saveStoreCategory,
    onSuccess: refetchStoreCategory
  });
}

export function useDeleteStoreCategory() {

  const refetchStoreCategory = useRefetchStoreCategory();

  return useMutation<
    AxiosResponse<BaseResponse<Response<{}>>>,
    AxiosError<BaseResponse<Response<{}>>>,
    any
  >({
    mutationKey: [URL_STORE_CATEGORY],
    mutationFn: deleteStoreCategory,
    onSuccess: refetchStoreCategory
  });
}

function useRefetchStoreCategory() {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries({ queryKey: [URL_STORE_CATEGORY] });
  };
}


export function useGetMasterStoreCategory(params: QueryModel) {
  return useQuery<
    AxiosResponse<BaseResponse<Pagination<StoreCategoryModel[]>>>,
    AxiosError<BaseResponse<Response<any>>>,
    ResponseList<StoreCategoryModel[]> | undefined
  >({
    keepPreviousData: true,
    queryKey: [URL_STORE_CATEGORY, params],
    queryFn: (_) => getStoreCategory(params, _.signal),
    select: selectStoreCategory,
  });
}