import {
  InputGroupProps
} from "./InputGroup.types";
import {
  Stack
} from "../../Layouts/Stack/Stack.page";
import {
  HelperText,
  Label
} from "../../Display";

export function InputGroup({
  className,
  children,
  helperText,
  inputProps,
  required,
  error,
  label,
  rule,
  ...props
}: InputGroupProps) {

  return (
    <Stack direction="col" gap={0.5}>
      {(!!label) && <Label {...{ required, error }}>{label}</Label>}
      <Stack direction="row" {...props}>{children}</Stack>
      {(!!helperText) && <HelperText {...{ error }}>{helperText}</HelperText>}
    </Stack>
  );
}
