import EmailIcon from './EmailIcon';
import EyeClose from './EyeClose';
import EyeOpen from './EyeOpen';

export * from './FileUploadPlaceholderIcon';
export * from './PdfInputIcon';
export * from './PasswordIcon';
export * from './CalendarIcon';
export * from './CaretIcon';
export * from './SearchIcon';

export {
  EmailIcon,
  EyeClose,
  EyeOpen,
}
