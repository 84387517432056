import {
  Controller,
  useFormContext
} from "react-hook-form";

import {
  Tags
} from "../../Inputs";
import {
  useDisabledField
} from "../HookForm/HookForm.context";
import {
  defaultRule
} from "../InputSelect/InputSelect.utils";
import {
  InputTagsProps
} from "./InputTags.types";

export function InputTags({
  disabled,
  rule,
  name,
  ...props
}: InputTagsProps) {

  const isDisabled = useDisabledField(name);
  const form = useFormContext();

  return (
    <Controller
      disabled={isDisabled || disabled}
      control={form.control}
      rules={defaultRule(rule, true)}
      name={name}
      render={({ field, fieldState }) => {

        const isError = !!fieldState.error;
        return (
          <Tags
            {...props}
            helperText={isError ? fieldState.error?.message?.toString() : props.helperText}
            required={!!rule?.required}
            disabled={field.disabled}
            tagRef={field.ref}
            error={isError}
            value={field.value}
            onChangeValue={(_: any) => {
              field.onChange(_);
              field.onBlur();
            }}
          />
        );
      }}
    />
  );
}
