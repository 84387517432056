import { GroupBase, Props } from "react-select";

import { OmitSelectProps } from "./Select.types";
import { Select } from "./Select.page";
import { SelectYearsProps } from "./SelectYears.types";

function getYears(offsetFromNow = 0, length = 100) {
  const years = (new Date()).getFullYear() - offsetFromNow;
  return Array.from({ length }, (_, index) => {
    const value = (years - index).toString();
    return { label: value, value };
  });
}

export function SelectYears<
  IsMulti extends boolean = false,
  Group extends GroupBase<any> = GroupBase<any>
>({
  offsetFromNow,
  onChangeValue,
  dataLength,
  value,
  ...props
}: SelectYearsProps & Omit<Props<any, IsMulti, Group>, OmitSelectProps | 'options' | 'value' | 'isMulti'>) {

  const master = getYears(offsetFromNow, dataLength);

  return (
    <Select
      onChangeValue={(_: any) => onChangeValue?.(_.value)}
      placeholder="Select year"
      options={master as any}
      value={!value ? undefined : { label: value }}
      {...props}
    />
  );
}
