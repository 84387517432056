import { twMerge } from "tailwind-merge";
import { UnderlineTabProps } from "./UnderlineTab.type";
import { Theme } from "./UnderlineTab.theme";
import { Ref, forwardRef } from "react";

function _UnderlineTab(
  {
    className,
    active,
    ...props
  }: UnderlineTabProps,
  forwardedRef: Ref<HTMLSpanElement>
) {

  return (
    <span
      ref={forwardedRef}
      className={twMerge(
        Theme.item,
        active && Theme.itemActive,
        className
      )}
      {...props}
    />
  );
}

export const UnderlineTab = forwardRef(_UnderlineTab);
