export const Theme = {

  container: 'relative',

  input: [
    'bg-gray-50 border border-transparent border-solid text-gray-900 text-sm rounded-lg block w-full p-2.5 ring-transparent outline-none min-h-[64px]',
    'placeholder:text-sm',
    'focus:border focus:border-primary-400 focus:ring focus:ring-primary-300',
    'dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white',
    'dark:focus:ring-primary-500 dark:focus:border-primary-500'
  ],
  inputError: [
    'border-danger',
    'focus:ring-danger-200 focus:border-danger',
    'dark:focus:ring-danger dark:focus:border-danger'
  ]
};