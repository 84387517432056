
const CampaignScreens = {

  CAMPAIGN: {
    PATH: '/campaign',
    TITLE: 'Campaign',
    TITLE_ALT: 'Campaign',
    PERMISSION: 'campaign.read',
  },

  LIST_CAMPAIGN: {
    PATH: '/campaign/list/:path',
    TITLE: 'Active Campaign',
    TITLE_ALT: 'Active Campaign',
    PERMISSION: 'campaign.read',
  },
  ADD_CAMPAIGN: {
    PATH: '/campaign/add',
    TITLE: 'Add Campaign',
    TITLE_ALT: 'Add Campaign',
    PERMISSION: 'campaign.create',
  },
  DETAIL_CAMPAIGN: {
    PATH: '/campaign/detail/:id',
    TITLE: 'Detail',
    TITLE_ALT: 'Detail',
    PERMISSION: 'campaign.read',
  },
  EDIT_CAMPAIGN: {
    PATH: '/campaign/edit/:id',
    TITLE: 'Edit Campaign',
    TITLE_ALT: 'Edit Campaign',
    PERMISSION: 'campaign.update',
  },

  LIST_CAMPAIGN_HISTORY: {
    PATH: '/campaign/list/history',
    TITLE: 'History Campaign',
    TITLE_ALT: 'History Campaign',
    PERMISSION: 'campaign.read',
  },
  DETAIL_CAMPAIGN_HISTORY: {
    PATH: '/campaign/detail/history/:id',
    TITLE: 'History Campaign',
    TITLE_ALT: 'History Campaign',
    PERMISSION: 'campaign.read',
  }
};

export default CampaignScreens;
