import { CalendarProps, DateObject, DatePickerProps } from "react-multi-date-picker";

export function parseInputValue(value?: any, props?: CalendarProps & DatePickerProps) {
  const _value = value.toString();
  if (!_value) {
    return (undefined);
  }
  if (props?.range && _value.includes(props.dateSeparator)) {
    const values = _value
      .split(props.dateSeparator)
      .map((_: any) => parseInputValue(_, props))
      .join(props.dateSeparator);
    return values;
  }
  const dateObject = new DateObject(_value);
  return dateObject.format(props?.format);
}

export function getDatePickerValue(value?: DateObject | DateObject[] | null, withTime= false): any {
  if (Array.isArray(value)) {
    return value.map((_)=> getDatePickerValue(_, withTime));
  }
  if (!!value) {
    const format = withTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';
    return value?.format(format);
  }
  return undefined;
}
