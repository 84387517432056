import { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../components/Layout/FallbackView'
import DashboardScreens from '../modules/dashboard/DashboardScreens'
import PromoScreens from '../modules/promo/PromoScreens'
import RewardsScreen from '../modules/rewards/RewardsScreens'
import DirectoryScreen from '../modules/directory/DirectoryScreen'
import SettingsScreens from '../modules/settings/SettingsScreens'
import UserAndPermissionScreens from '../modules/user_and_permissions/UserAndPermissionsScreens'
import CustomerScreens from '../modules/customer/CustomerScreens'
import ReceiptReportScreens from '../modules/receipt_report/ReceiptReportScreens'
import ManageMobileAppScreen from '../modules/manage_mobile_app/ManageMobileAppScreens'
import CampaignScreens from '../modules/campaign/CampaignScreens'
import CampaignRoutes from '../modules/campaign/CampaignRoutes'
import PushNotificationScreens from '../modules/push_notification/PushNotificationScreens'
import WelcomePage from '../modules/welcome/Welcome.page'
import ReportScreens from '../modules/report/ReportScreens'
import AuditTrailScreens from '../modules/audit_trail/AuditTrailScreen'
import AuditTrailRoutes from '../modules/audit_trail/AuditTrailRoutes'

const PromoRoutes = lazy(() => import('../modules/promo/PromoRoutes'));
const RewardsRoutes = lazy(() => import('../modules/rewards/RewardsRoutes'));
const DashboardPage = lazy(() => import('../modules/dashboard/DashboardRoutes'));
const DirectoryRoutes = lazy(() => import('../modules/directory/DirectoryRoutes'));
const SettingsRoutes = lazy(() => import('../modules/settings/SettingsRoutes'));
const CustomerRoutes = lazy(() => import('../modules/customer/CustomerRoutes'));
const UserAndPermissionsRoutes = lazy(() => import('../modules/user_and_permissions/UserAndPermissionsRoutes'));
const ManageMobileAppRoutes = lazy(() => import('../modules/manage_mobile_app/ManageMobileAppRoutes'));
const ReceiptReportRoutes = lazy(() => import('../modules/receipt_report/ReceiptReportRoutes'));
const PushNotificationRoutes = lazy(() => import('../modules/push_notification/PushNotificationRoutes'));
const ReportRoutes = lazy(() => import('../modules/report/ReportRoutes'));

export function PrivateRoutes() {

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path={DirectoryScreen.DIRECTORY.PATH} component={DirectoryRoutes} />
        <Route path={PromoScreens.PROMO.PATH} component={PromoRoutes} />
        <Route path={RewardsScreen.REWARDS.PATH} component={RewardsRoutes} />
        <Route path={SettingsScreens.SETTINGS.PATH} component={SettingsRoutes} />
        <Route path={DashboardScreens.DASHBOARD.PATH} component={DashboardPage} />
        <Route path={CustomerScreens.CUSTOMER.PATH} component={CustomerRoutes} />
        <Route path={UserAndPermissionScreens.USER_AND_PERMISSIONS.PATH} component={UserAndPermissionsRoutes} />
        <Route path={ManageMobileAppScreen.MANAGE_MOBILE_APP.PATH} component={ManageMobileAppRoutes} />
        <Route path={CampaignScreens.CAMPAIGN.PATH} component={CampaignRoutes} />
        <Route path={PushNotificationScreens.PUSH_NOTIFICATION.PATH} component={PushNotificationRoutes} />
        <Route path={ReceiptReportScreens.RECEIPT_REPORT.PATH} component={ReceiptReportRoutes} />
        <Route path={ReportScreens.REPORT.PATH} component={ReportRoutes} />
        <Route path={AuditTrailScreens.AUDIT_TRAIL.PATH} component={AuditTrailRoutes} />
        <Route path={"/welcome"} component={WelcomePage} />

        <Redirect from='/auth' to='/welcome' />
        <Redirect exact from='/' to='/welcome' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
