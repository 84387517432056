import {
  GroupBase,
  Props,
} from "react-select";

import {
  safeArray
} from "src/app/utils/array-utils";
import {
  OmitSelectAsyncProps,
  SelectAsyncAdditionalPayload,
  SelectAsyncProps
} from "./SelectAsync.types";
import {
  SelectAsyncPaginate
} from "./Select.page";
import {
  Components
} from "./components";

export function SelectAsync<
  Option,
  IsMulty extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  onChangeValue,
  fetchApi,
  onChange,
  payload,
  fields,
  ...props
}: SelectAsyncProps<Option> & Omit<Props<Option, IsMulty, Group>, OmitSelectAsyncProps>) {

  const loadOptions = async (search: string, _: unknown, additional: SelectAsyncAdditionalPayload) => {
    try {

      const response = await fetchApi({ ...additional, search, ...payload });
      const {
        pagination,
        content
      } = response?.data?.response_output?.list || {};
      const options = safeArray(content).map((_: any, __: number) => ({ ...props.selectOption(_, __), ..._ }));
      const totalPage = Math.ceil(pagination?.total! / pagination?.rows_per_page!);

      return ({
        hasMore: additional.page < totalPage,
        additional: { ...additional, page: additional.page + 1 },
        options
      });

    } catch (error) {
      console.log("🚀 ~ file: SelectAsync.page.tsx:64 ~ loadOptions ~ error:", error);
      return ({
        hasMore: false,
        options: []
      });
    }
  };

  return (
    <SelectAsyncPaginate
      loadOptions={loadOptions as any}
      cacheUniqs={[JSON.stringify(payload)]}
      components={Components}
      debounceTimeout={300}
      additional={{ page: 1, limit: 100 }}
      onChange={(_: any) => onChangeValue?.(_)}
      {...props}
    />
  );
}
