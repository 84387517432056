import { SVGProps } from "react";
import { twMerge } from "tailwind-merge";

export function DangerIconTriangle({
  className,
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
      className={twMerge("fill-danger-600", className)}
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M13.77 5.862c-.75-1.424-2.79-1.424-3.54 0L3.543 18.568c-.701 1.332.265 2.932 1.77 2.932h13.374c1.505 0 2.471-1.6 1.77-2.932L13.77 5.862zm-3.107 4.78a1 1 0 01.99-1.142h.694a1 1 0 01.99 1.141l-.766 5.364a.576.576 0 01-1.142 0l-.766-5.364zM13 18.5a1 1 0 11-2 0 1 1 0 012 0z"
        clipRule="evenodd"
      />
    </svg>
  );
}
