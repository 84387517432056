
export const Theme = {
  container: [
    'flex text-sm text-gray-700 px-4 py-2.5 cursor-pointer',
    'first:mt-2 last:mb-2',
    'hover:bg-gray-100',
    'dark:text-gray-200',
    'dark:hover:bg-gray-600 dark:hover:text-white'
  ],
  startIcon: [
    'w-3.5 h-3.5 me-2'
  ],
}
