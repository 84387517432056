import { twMerge } from "tailwind-merge";

import { Theme } from "./Thumbnail.theme";
import { ThumbnailProps, ThumbnailRatio } from "./Thumbnail.types";

export function Thumbnail({
  className,
  ratio,
  src,
  alt,
  ...props
}: Readonly<ThumbnailProps>) {

  return (
    <div className={twMerge(Theme.container, ratio && ThumbnailRatio[ratio], className)}>
      <img
        {...props}
        className={twMerge(Theme.image, ratio && ThumbnailRatio[ratio], className)}
        alt={alt ?? "thumbnail"}
        src={src ?? "https://placehold.co/320x240"}
      />
    </div>
  );
}
