import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { URL_GET_PROFILE, URL_USER, deleteUser, getProfile, getUser, getUserDetail, saveUser } from "../services/User.service";
import { createSelector } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { BaseResponse, Pagination, Response, ResponseFieldError, ResponseList } from "src/app/models/api.types";
import { UserModel } from "../models/User.model";
import { useApiParams } from "src/app/hooks";

const selectProfile = createSelector(
  (state: AxiosResponse<BaseResponse<Response<UserModel>>>) => state.data,
  (data: BaseResponse<Response<UserModel>>) => data.response_output?.detail
);
// Define selector
const selectUser = createSelector(
  (state: AxiosResponse<BaseResponse<Pagination<UserModel[]>>>) => state.data,
  (data: BaseResponse<Pagination<UserModel[]>>) => data.response_output?.list
);

const selectUserDetail = createSelector(
  (state: AxiosResponse<BaseResponse<Response<UserModel>>>) => state.data,
  (data: BaseResponse<Response<UserModel>>) => {
    const {
      created_by,
      updated_by,
      created_at,
      updated_at,
      deleted_at,
      directories,
      merchants,
      merchant,
      ...rest
    } = data.response_output?.detail ?? {};

    const newMerchants = merchants?.map((_) => ({ label: _?.merchant_name, value: _?.id }))
    const newDirectories = directories?.map((_) => ({ label: _?.directory_name, value: _?.id, ..._ }))
    
    return {
      ...rest,
      directories: newDirectories,
      directory: newDirectories?.[0],
      merchants: newMerchants,
      merchant: newMerchants?.[0]
    };
  }
)

// Refetch query
const useRefetchUser = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries({ queryKey: [URL_USER] });
  };
}

export function useGetProfile() {

  return useQuery({
    queryKey: [URL_GET_PROFILE],
    queryFn: getProfile,
    select: selectProfile
  });
}

// Get user
export const useGetUser = () => {
  const defaultParams = {
    role_ids: '',
    directory_ids: '',
  };
  const [params] = useApiParams({ defaultValues: defaultParams });

  return useQuery<
    AxiosResponse<BaseResponse<Pagination<UserModel[]>>>,
    AxiosError<BaseResponse<Response<any>>>,
    ResponseList<UserModel[]> | undefined
  >({
    keepPreviousData: true,
    queryKey: [URL_USER, params],
    queryFn: () => getUser(params),
    select: selectUser,
  });
}

// Get user detail
export const useGetUserDetail = (id?: string) => {
  return useQuery<
    AxiosResponse<BaseResponse<Response<UserModel>>>,
    AxiosError<BaseResponse<Response<any>>>,
    UserModel | undefined
  >({
    keepPreviousData: false,
    queryKey: [`${URL_USER}/${id}`],
    queryFn: () => getUserDetail(id!),
    select: selectUserDetail,
    enabled: !!id
  })
}

// Save User
export const useSaveUser = () => {
  const refetchUser = useRefetchUser();
  return useMutation<
    AxiosResponse<BaseResponse<Response<UserModel>>>,
    AxiosError<BaseResponse<ResponseFieldError>>,
    UserModel
  >({
    mutationKey: [URL_USER],
    mutationFn: saveUser,
    onSuccess: refetchUser
  })
}

// Delete User
export const useDeleteUser = (id?: string) => {
  const refetchUser = useRefetchUser();
  return useMutation<
    AxiosResponse<BaseResponse<Response<{}>>>,
    AxiosError<BaseResponse<Response<any>>>,
    {} | undefined
  >({
    mutationKey: [`${URL_USER}/${id}`],
    mutationFn: () => deleteUser(id!),
    onSuccess: refetchUser
  })
}