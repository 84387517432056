import {
  UseMutationOptions,
  useMutation
} from "@tanstack/react-query";
import {
  AxiosError,
  AxiosResponse
} from "axios";

import {
  BaseResponse,
  Response
} from "src/app/models/api.types";
import {
  URL_REQUEST_UPDATE_AUTH,
  URL_UPDATE_PASSWORD,
  URL_VERIFY_TOKEN,
  requestUpdateAuth,
  updatePassword,
  verifyToken
} from "../services/SetPassword.service";

export function useRequestUpateAuth<T extends string>(
  options?: UseMutationOptions<
    AxiosResponse<BaseResponse<Response<{}>>>,
    AxiosError<BaseResponse<any>>,
    Record<T, string>
  >
) {

  return useMutation({
    mutationKey: [URL_REQUEST_UPDATE_AUTH],
    mutationFn: requestUpdateAuth,
    ...options
  })
}

export function useVerifyToken<T extends string>(
  options?: UseMutationOptions<
    AxiosResponse<BaseResponse<Response<{ is_verify: boolean }>>>,
    AxiosError<BaseResponse<any>>,
    Record<T, string>
  >
) {

  return useMutation({
    mutationKey: [URL_VERIFY_TOKEN],
    mutationFn: verifyToken,
    retry: false,
    ...options
  })
}

export function useUpdatePassword<T extends string>(
  options?: UseMutationOptions<
    AxiosResponse<BaseResponse<Response<{}>>>,
    AxiosError<BaseResponse<any>>,
    Record<T, string>
  >
) {

  return useMutation({
    mutationKey: [URL_UPDATE_PASSWORD],
    mutationFn: updatePassword,
    ...options
  })
}
