import axios from "axios";
import { BaseResponse, Pagination, QueryModel, Response } from "src/app/models/api.types";
import { RoleModel } from "../models/Role.model";
export const URL_ROLE = '/v1/cms/roles';

export const getRole = async (params?: QueryModel) => {
  return axios.get<BaseResponse<Pagination<RoleModel[]>>>(URL_ROLE, {
    params
  })
}

export const getRoleDetail = (id: string) => {
  return axios.get<BaseResponse<Response<RoleModel>>>(`${URL_ROLE}/${id}`)
}

export const saveRole = (params: RoleModel) => {
  if (!params.id) {
    return axios.post<BaseResponse<Response<RoleModel>>>(URL_ROLE, params)
  }

  return axios.put<BaseResponse<Response<RoleModel>>>(`${URL_ROLE}/${params.id}`, params)
}

export const deleteRole = (id: string) => {
  return axios.delete(`${URL_ROLE}/${id}`)
}