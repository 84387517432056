import axios from "axios";

import {BaseResponse, Pagination, Response, ResponseList} from 'src/app/models/api.types'
import {AuditTrailModel} from '../models/AuditTrail.model'

export const URL_AUDIT_TRAIL = '/v1/cms/audit-trail';
export const URL_AUDIT_TRAIL_DETAIL = '/v1/cms/audit-trail/detail';

// get receipt report
export async function getAuditTrail(params: any) {
  return axios.get<BaseResponse<Pagination<AuditTrailModel[]>>>(URL_AUDIT_TRAIL, { params });
}

// get action list
export async function getActionList(params: any) {
  return axios.get<BaseResponse<Pagination<any[]>>>(`${URL_AUDIT_TRAIL}/actions/`, { params });
}

// get detail
export const getAuditTrailDetail = (id: string) => {
  return axios.get<BaseResponse<Response<AuditTrailModel>>>(`${URL_AUDIT_TRAIL}/${id}`)
}