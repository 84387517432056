import { twMerge } from "tailwind-merge";
import { Typography } from "../../Display";
import { Stack } from "../../Layouts";
import { ContentHeaderProps } from "./ContentHeader.types";

export function ContentHeader({
  className,
  children,
  title,
  ...props
}: ContentHeaderProps) {

  return (
    <Stack
      className={twMerge("border-b px-8 py-6 dark:border-gray-700", className)}
      direction="row"
      items="center"
      gap={4}
      {...props}
    >
      <Typography variant="h5" className="flex-1">
        {title}
      </Typography>
      {children}
    </Stack>
  );
}
