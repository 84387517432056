import { ReactNode } from "react";

import { hasText } from "./string-utils";


export interface DisplayLabelOptions<T> {
  renderValue?: (value: T) => ReactNode,
  placeholder?: string
  validate?: (value: any) => boolean
}
export function displayValue<T extends any>(value: T, options?: DisplayLabelOptions<T>) {

  const {
    renderValue = (_: any) => _,
    placeholder = '-',
    validate = hasText
  } = options || {};
  if (validate(value)) {
    const label = renderValue(value);
    return Array.isArray(label) ? label.join(', ') : label;
  }
  return placeholder;
}
