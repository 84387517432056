import {useDialogContext} from 'src/app/components'
import {useGetAuditTrailDetail} from '../../providers/AuditTrail.provider'
import {useParams} from 'react-router-dom'

export function useDialogContentAuditTrailDetail() {

  const dialogContext = useDialogContext<{id: string}>()
  const { id } = useParams<{ id?: string }>()
  const auditTrailDetail = useGetAuditTrailDetail(id)

  return ({
    auditTrailDetail,
  })
}
