import {
  Link as RouterLink,
  LinkProps
} from "react-router-dom";
import {
  twMerge
} from "tailwind-merge";

export function Link({ className, ...props }: LinkProps) {

  return (
    <RouterLink
      className={twMerge("text-base text-info-600", className)}
      {...props}
    />
  );
}
